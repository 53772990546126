import {combineReducers} from "redux";
import type {Reducer} from "redux";
import type {
    NativeExperienceTriggeredAction,
    PersonalizationState,
} from "@atg-global-shared/personalization-types";
import nativeExperiences from "./nativePersonalizationReducer";

// TODO: lift this up one level (remove `combineReducers`) at some point
const personalizationReducer: Reducer<
    PersonalizationState,
    NativeExperienceTriggeredAction
> = combineReducers({
    nativeExperiences,
});

export default personalizationReducer;
