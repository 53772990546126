import * as Sfx from "redux-saga/effects";
import type {SagaIterator} from "redux-saga";
import type {FavoriteGame} from "@atg-casino-shared/types-favorites";
import {AuthActions} from "@atg-shared/auth";
import * as UserActionTypes from "@atg-global-shared/user/userActionTypes";
import * as UserSelectors from "@atg-global-shared/user/userSelectors";
import {
    selectFavoriteGame,
    selectFavoriteGamesCount,
    selectIsGameFavorite,
} from "./favorites.selectors";
import type {ToggleFavoriteGameAction} from "./favorites.types";
import * as actions from "./favorites.actions";
import * as constants from "./favorites.constants";

function* handleFavoriteGame({
    payload: {gameId},
}: ToggleFavoriteGameAction): SagaIterator {
    const {userId} = yield Sfx.select(UserSelectors.getUser);

    if (!userId) {
        yield Sfx.put(AuthActions.checkAuth(true));
        return;
    }

    const isFavorite = yield Sfx.select(selectIsGameFavorite(gameId));

    if (!isFavorite) {
        const newGame = {gameId, created: new Date().toISOString()};
        const games = yield Sfx.select(selectFavoriteGamesCount);

        if (games >= constants.FAVORITE_GAMES_LIMIT) {
            yield Sfx.put(actions.toggleLimitModal(true));
            return;
        }

        yield Sfx.put(actions.gameAddedFetchAction(newGame));
    } else {
        const game: FavoriteGame = yield Sfx.select(selectFavoriteGame(gameId));

        yield Sfx.put(actions.gameDeletedFetchAction(game));
    }
}

function* onUserLoggedIn(): SagaIterator {
    const {userId} = yield Sfx.select(UserSelectors.getUser);

    if (!userId) return;

    yield Sfx.put(actions.fetchFavoritesGames());
}

function* onUserLoggedOut(): SagaIterator {
    yield Sfx.put(actions.clearFavoriteGames());
}

export function* favoritesSaga(): SagaIterator<void> {
    yield Sfx.takeLatest(constants.TOGGLE_FAVORITE_GAME, handleFavoriteGame);
    yield Sfx.takeLatest(UserActionTypes.LOGOUT_FINISHED, onUserLoggedOut);
    yield Sfx.takeLeading(
        [
            UserActionTypes.RECEIVE_USER,
            UserActionTypes.LOGIN_FINISHED,
            AuthActions.AUTH_CHECK_RESPONSE,
            AuthActions.AUTH_SET_ROLES,
        ],
        onUserLoggedIn,
    );
}
