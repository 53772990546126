import type {
    Action,
    SelfExclusionState,
    SelfExclusions,
    Vertical,
} from "@atg-responsible-gambling-shared/self-exclusion-types";
import {SelfExclusionConstants} from "@atg-responsible-gambling-shared/self-exclusion-types";
import {ExclusionMessages} from "@atg-responsible-gambling-shared/self-exclusion-utils";

const {
    CLEAR_SELF_EXCLUSION_STATUS_MESSAGES,
    REQUEST_SELF_EXCLUSION,
    RECEIVE_SELF_EXCLUSION,
    REQUEST_SET_SELF_EXCLUSION,
    RECEIVE_SET_SELF_EXCLUSION,
} = SelfExclusionConstants;

const INITIAL_EXCLUSION_STATE = {
    error: null,
    isLoading: false,
    statusMessage: null,
    selfExclusions: {},
};

const Verticals: Array<Vertical> = ["CASINO", "SPORTSBOOK", "HORSE_BETTING"];

const updatedSelfExclusions = (
    selfExclusionsState: SelfExclusions,
    responseData: SelfExclusions,
): SelfExclusions =>
    Verticals.reduce((newExclusions, vertical): SelfExclusions => {
        if (selfExclusionsState && responseData && responseData[vertical]) {
            newExclusions[vertical] = responseData[vertical];
        } else {
            newExclusions[vertical] = selfExclusionsState[vertical];
        }
        return newExclusions;
    }, {} as SelfExclusions);

const selfExclusion = (
    state: SelfExclusionState = INITIAL_EXCLUSION_STATE,
    action: Action,
): SelfExclusionState => {
    switch (action.type) {
        case REQUEST_SELF_EXCLUSION:
        case REQUEST_SET_SELF_EXCLUSION:
            return {
                ...state,
                isLoading: true,
                error: false,
            };
        case RECEIVE_SET_SELF_EXCLUSION:
            if (action.error) {
                return {
                    ...state,
                    isLoading: false,
                    statusMessage: ExclusionMessages.getErrorMessage(
                        action.payload.response.meta.code,
                    ),
                };
            }
            return {
                ...state,
                isLoading: false,
                statusMessage: ExclusionMessages.SET_SELF_EXCLUSION_SUCCESS,
                selfExclusions: updatedSelfExclusions(
                    state.selfExclusions,
                    action.payload.selfExclusions,
                ),
            };
        case RECEIVE_SELF_EXCLUSION:
            if (action.error) {
                return {
                    ...state,
                    error: true,
                    isLoading: false,
                };
            }
            return {
                ...state,
                error: false,
                isLoading: false,
                selfExclusions: action.payload.selfExclusions,
            };
        case CLEAR_SELF_EXCLUSION_STATUS_MESSAGES:
            return {
                ...state,
                isLoading: false,
                statusMessage: null,
            };
        default:
            return state;
    }
};

export default selfExclusion;
