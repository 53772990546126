import type {
    NativeExperiencesState,
    NativeExperienceTriggeredAction,
} from "@atg-global-shared/personalization-types";
// eslint-disable-next-line import/no-extraneous-dependencies
import {NATIVE_EXPERIENCE_TRIGGERED} from "@atg-global-shared/personalization-types";

function nativeExperiences(
    state: NativeExperiencesState = {},
    action: NativeExperienceTriggeredAction,
): NativeExperiencesState {
    const {type, payload} = action;
    if (type === NATIVE_EXPERIENCE_TRIGGERED) {
        const {experienceId, fields, variation} = payload;
        return {
            ...state,
            [experienceId]: {
                variation,
                fields,
            },
        };
    }
    return state;
}

export default nativeExperiences;
