import type {GameState} from "./game.flow.types";
import type {GameActions} from "./game.flow.actions";
import {
    START_GAME_FLOW,
    SET_LAUNCH_DATA,
    SET_LAUNCH_ERROR,
    SHOW_GAME,
    STOP_GAME,
    OPEN_REMOTE_GAME,
} from "./game.flow.actions";

export const gameFlowReducer = (
    state: GameState = {frameLoaded: false},
    action: GameActions,
) => {
    switch (action.type) {
        case START_GAME_FLOW:
            return {
                frameLoaded: false,
                game: action.payload.game,
                gameType: action.payload.gameType,
            };
        case SET_LAUNCH_DATA:
            return {...state, launchData: action.payload};
        case SET_LAUNCH_ERROR:
            return {...state, error: action.payload};
        case SHOW_GAME:
            return {...state, frameLoaded: true};
        case STOP_GAME:
            return {error: state?.error, frameLoaded: false};
        case OPEN_REMOTE_GAME:
            return {...state, remoteLink: action.payload};
        default:
            return state;
    }
};
