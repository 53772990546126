import {FETCH, call} from "@atg-shared/fetch-redux";
import type {
    ClearSelfExclusionStatusMessagesAction,
    FetchSelfExclusionAction,
    SelfExclusionFormattedValue,
    Set24hCasinoPanicSelfExclusionAction,
    SetSelfExclusionAction,
} from "@atg-responsible-gambling-shared/self-exclusion-types";
import {SelfExclusionConstants} from "@atg-responsible-gambling-shared/self-exclusion-types";
import * as SelfExclusionApi from "./api";

const {
    REQUEST_PANIC_SELF_EXCLUSION,
    REQUEST_SELF_EXCLUSION,
    REQUEST_SET_SELF_EXCLUSION,
    RECEIVE_PANIC_SELF_EXCLUSION,
    RECEIVE_SELF_EXCLUSION,
    RECEIVE_SET_SELF_EXCLUSION,
    CLEAR_SELF_EXCLUSION_STATUS_MESSAGES,
} = SelfExclusionConstants;

export const getSelfExclusions = (): FetchSelfExclusionAction => ({
    type: FETCH,
    payload: {
        requestAction: REQUEST_SELF_EXCLUSION,
        receiveAction: RECEIVE_SELF_EXCLUSION,
        callApi: call(SelfExclusionApi.getSelfExclusions),
    },
});

export const setSelfExclusion = (
    selfExclusions: Array<SelfExclusionFormattedValue>,
    accessToken: string,
): SetSelfExclusionAction => ({
    type: FETCH,
    payload: {
        requestAction: REQUEST_SET_SELF_EXCLUSION,
        receiveAction: RECEIVE_SET_SELF_EXCLUSION,
        callApi: call(SelfExclusionApi.setSelfExclusion, {selfExclusions, accessToken}),
    },
});

export const set24hCasinoPanicSelfExclusion =
    (): Set24hCasinoPanicSelfExclusionAction => ({
        type: FETCH,
        payload: {
            requestAction: REQUEST_PANIC_SELF_EXCLUSION,
            receiveAction: RECEIVE_PANIC_SELF_EXCLUSION,
            callApi: call(SelfExclusionApi.setCasino24hPanicSelfExclusion),
        },
    });

export const clearSelfExclusionStatusMessages =
    (): ClearSelfExclusionStatusMessagesAction => ({
        type: CLEAR_SELF_EXCLUSION_STATUS_MESSAGES,
    });
