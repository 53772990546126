import {fetchAuthorized} from "@atg-shared/auth";
import atgRequest from "@atg-shared/fetch";
import type {AtgResponse} from "@atg-shared/fetch-types";
import {RGS_SELF_EXCLUSION_SERVICE_URL} from "@atg-shared/service-url";
import type {
    SelfExclusionFormattedValue,
    SelfExclusionResponse,
} from "@atg-responsible-gambling-shared/self-exclusion-types";

export const setSelfExclusion = (payload: {
    selfExclusions: Array<SelfExclusionFormattedValue>;
    accessToken: string;
}): Promise<AtgResponse<SelfExclusionResponse>> => {
    const {selfExclusions, accessToken} = payload;

    return atgRequest(
        RGS_SELF_EXCLUSION_SERVICE_URL,
        {
            method: "PUT",
            body: JSON.stringify({
                selfExclusions,
            }),
        },
        accessToken,
    );
};

export const setCasino24hPanicSelfExclusion = () =>
    fetchAuthorized(`${RGS_SELF_EXCLUSION_SERVICE_URL}/casinopanic`, {
        method: "PUT",
        body: JSON.stringify({
            selfExclusions: [
                {
                    vertical: "CASINO",
                    exclusionPeriod: "TWENTY_FOUR_HOURS",
                },
            ],
        }),
    });

export const getSelfExclusions = () =>
    fetchAuthorized<SelfExclusionResponse>(
        `${RGS_SELF_EXCLUSION_SERVICE_URL}`,
        {
            method: "GET",
        },
        {
            memberFlowEnabled: false,
        },
    );
