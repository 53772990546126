import type {Game} from "@atg-casino-shared/types-game";
import type {GameType, ErrorResponse} from "./game.flow.types";

export const START_GAME_FLOW = "casino-game/START_GAME_FLOW";
export const START_REMOTE_GAME_FLOW = "casino-game/START_REMOTE_GAME_FLOW";
export const SET_LAUNCH_DATA = "casino-game/SET_LAUNCH_DATA";
export const SET_LAUNCH_ERROR = "casino-game/SET_LAUNCH_ERROR";
export const SHOW_GAME = "casino-game/SHOW_GAME";
export const STOP_GAME = "casino-game/STOP_GAME";
export const OPEN_REMOTE_GAME = "casino-game/OPEN_REMOTE_GAME";

export type StartGameFlowAction = {
    type: typeof START_GAME_FLOW;
    payload: {game: Game; gameType: GameType; launchedBy?: string};
};

export type StartRemoteGameFlowAction = {
    type: typeof START_REMOTE_GAME_FLOW;
    payload: {game: Game; src?: WindowProxy};
};

export type SetLaunchDataAction = {
    type: typeof SET_LAUNCH_DATA;
    payload: string;
};

export type SetLaunchErrorAction = {
    type: typeof SET_LAUNCH_ERROR;
    payload: string;
};

export type StopGameAction = {
    type: typeof STOP_GAME;
};

export type ShowGameAction = {
    type: typeof SHOW_GAME;
};

// Will be used when `StartRemoteGameFlowAction` `src` is `undefined`.
// Used for mini Bingo games from the atgapp.
export type OpenRemoteGameAction = {
    type: typeof OPEN_REMOTE_GAME;
    payload: string;
};

export type GameActions =
    | StartGameFlowAction
    | SetLaunchDataAction
    | SetLaunchErrorAction
    | StopGameAction
    | ShowGameAction
    | OpenRemoteGameAction;

export const startGameFlow = (
    game: Game,
    gameType: GameType,
    launchedBy?: string,
): StartGameFlowAction => ({
    type: START_GAME_FLOW,
    payload: {
        game,
        gameType,
        launchedBy,
    },
});

export const startRemoteGameFlow = (
    game: Game,
    src?: WindowProxy,
): StartRemoteGameFlowAction => ({
    type: START_REMOTE_GAME_FLOW,
    payload: {game, src},
});

export const setLaunchData = (data: string): SetLaunchDataAction => ({
    type: SET_LAUNCH_DATA,
    payload: data,
});

export const setLaunchError = (error: ErrorResponse): SetLaunchErrorAction => ({
    type: SET_LAUNCH_ERROR,
    payload: error,
});

export const showGame = (): ShowGameAction => ({
    type: SHOW_GAME,
});

export const stopGame = (): StopGameAction => ({
    type: STOP_GAME,
});
