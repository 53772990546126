import type {GlobalState} from "@atg-casino-shared/types-root";
import type {FavoriteGame} from "@atg-casino-shared/types-favorites";
import {createSelector} from "reselect";

const selectFavoriteGames = (state: GlobalState) => state.casino.favorites.games;

export const selectFavoriteGamesCount = createSelector(
    selectFavoriteGames,
    (games) => Object.keys(games).length,
);

export const selectFavoriteGameIds = createSelector(selectFavoriteGames, (games) =>
    Object.values(games).map((game) => game.gameId),
);

export const selectFavoriteGame =
    (gameId: string) =>
    (state: GlobalState): FavoriteGame =>
        state.casino.favorites.games[gameId];

export const selectIsGameFavorite =
    (gameId: string) =>
    (state: GlobalState): boolean =>
        !!state.casino.favorites.games?.[gameId];

export const selectLimitModalVisible = (state: GlobalState): boolean =>
    state.casino.favorites.limitModalVisible;

export const selectIsFavoritesLoading = (state: GlobalState): boolean =>
    state.casino.favorites.isLoading;

export const selectIsError = (state: GlobalState): boolean =>
    state.casino.favorites.isError;
