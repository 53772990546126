import {get} from "lodash";
import root from "window-or-global";
import {pureFetch} from "@atg-shared/fetch";
import type {AtgResponse} from "@atg-shared/fetch-types";
import type {CollectionResponse} from "@atg-casino-shared/types-collection";
import type {GameResponse, GameMin} from "@atg-casino-shared/types-game";
import type {PagesResponse, PageResponse} from "@atg-casino-shared/types-page";
import type {MenuResponse} from "@atg-casino-shared/types-menu";
import type {TagResponse} from "@atg-casino-shared/types-tag";

const getConfigUrl = () => get(root, "clientConfig.casino.cloudfrontUrl");

export const fetchPages = (): Promise<AtgResponse<PagesResponse>> =>
    pureFetch(`${getConfigUrl()}/pages`);

export const fetchPage = (pageId: string): Promise<AtgResponse<PageResponse>> =>
    pureFetch(`${getConfigUrl()}/pages/${pageId}`);

export const fetchTag = (tagId: string): Promise<AtgResponse<TagResponse>> =>
    pureFetch(`${getConfigUrl()}/tags/${tagId}`);

export const fetchGame = (gameId: string): Promise<AtgResponse<GameResponse>> =>
    pureFetch(`${getConfigUrl()}/games/${gameId}`);

export const fetchGames = (): Promise<AtgResponse<Array<GameMin>>> =>
    pureFetch(`${getConfigUrl()}/games`);

export const fetchCollection = (
    collectionId: string,
): Promise<AtgResponse<CollectionResponse>> =>
    pureFetch(`${getConfigUrl()}/collections/${collectionId}`);

export const fetchMenu = (): Promise<AtgResponse<MenuResponse>> =>
    pureFetch(`${getConfigUrl()}/leftmenu`);
