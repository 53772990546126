import {fetchAuthorized} from "@atg-shared/auth";
import atgFetch from "@atg-shared/fetch";
import {RGS_SERVICE_URL, PAYMENT_URL} from "@atg-shared/service-url";
import type {FetchAction} from "@atg-shared/fetch-types";
import {FETCH, call} from "@atg-shared/fetch-redux";
import type {DepositBudgetOptions} from "@atg-responsible-gambling-shared/budget-types";
import type {
    LogoutFinishedAction,
    LoginFinishedAction,
    ReceiveUserAction,
} from "@atg-global-shared/user/userActions";
import {RgsLimitTypeEnum} from "@atg-responsible-gambling-shared/limits-types";
import type {
    RgsLimits,
    SimplifiedRgsLimits,
    VerticalEnum,
    PeriodicLimit,
    LoginTimeLimitResponse,
    MultiPeriodicLimit,
    RgsVerticalLimit,
} from "@atg-responsible-gambling-shared/limits-types";
import {broadcastAction} from "atg-store-addons";
import type {GlobalLimitsState} from "./limits.types";
import type {RgsLimitsContext, Limits} from "./rgsApi";
import fetchRgsLimitsApi, {
    deleteVerticalRgsLimits as deleteOptionalRgsLimits,
} from "./rgsApi";

/**
 * RESET RgsLimits Status
 */
export const RESET_STATUS = "limits/RESET_STATUS";
export const APP_SPORT_USER_READY = "limits/APP_SPORT_USER_READY";

export type ResetStatusAction = {
    type: typeof RESET_STATUS;
};

export const RESET_RGS_LIMITS_STATUS = "limits/RESET_RGS_LIMITS_STATUS";

export type ResetRgsFetchStatusAction = {
    type: typeof RESET_RGS_LIMITS_STATUS;
    context: RgsLimitsContext;
};

export const resetFetchRgsStatus = (context: RgsLimitsContext) => ({
    type: RESET_RGS_LIMITS_STATUS,
    context,
});

export const resetStatus = (): ResetStatusAction =>
    broadcastAction({
        type: RESET_STATUS,
    });

/**
 * FETCH RgsLimits
 *
 */

export const REQUEST_GET_RGS_LIMITS = "limits/REQUEST_GET_RGS_LIMITS";
export const RECEIVE_GET_RGS_LIMITS = "limits/RECEIVE_GET_RGS_LIMITS";

export type FetchRgsLimitsAction = FetchAction<
    typeof REQUEST_GET_RGS_LIMITS,
    typeof RECEIVE_GET_RGS_LIMITS,
    RgsLimits,
    GlobalLimitsState,
    RgsLimitsContext
>;

export const fetchRgsLimits = (
    context: RgsLimitsContext,
    limits?: {loss?: Limits | SimplifiedRgsLimits; time?: Limits},
    abTestVariation?: number,
): FetchRgsLimitsAction => ({
    type: FETCH,
    payload: {
        requestAction: REQUEST_GET_RGS_LIMITS,
        receiveAction: RECEIVE_GET_RGS_LIMITS,
        callApi: call(fetchRgsLimitsApi, context, limits, abTestVariation),
        context: {...context, set: Boolean(limits)},
    },
});

/**
 * DELETE RgsLimits API
 */

export const REQUEST_DELETE_RGS_LIMITS = "limits/REQUEST_DELETE_RGS_LIMITS";
export const RECEIVE_DELETE_RGS_LIMITS = "limits/RECEIVE_DELETE_RGS_LIMITS";

export type DeleteRgsLimitsAction = FetchAction<
    typeof REQUEST_DELETE_RGS_LIMITS,
    typeof RECEIVE_DELETE_RGS_LIMITS,
    RgsLimits,
    GlobalLimitsState,
    RgsLimitsContext
>;

export const deleteRgsLimits = (vertical: VerticalEnum): DeleteRgsLimitsAction => ({
    type: FETCH,
    payload: {
        requestAction: REQUEST_DELETE_RGS_LIMITS,
        receiveAction: RECEIVE_DELETE_RGS_LIMITS,
        callApi: call(deleteOptionalRgsLimits, vertical),
        context: {vertical, limitType: RgsLimitTypeEnum.LOSS},
    },
});

/**
 * GET LoginTimeLimit API
 *
 */

export const REQUEST_GET_LOGIN_TIME_LIMITS = "limits/REQUEST_GET_LOGIN_TIME_LIMITS";
export const RECEIVE_GET_LOGIN_TIME_LIMITS = "limits/RECEIVE_GET_LOGIN_TIME_LIMITS";

export type FetchLoginTimeLimitsAction = FetchAction<
    typeof REQUEST_GET_LOGIN_TIME_LIMITS,
    typeof RECEIVE_GET_LOGIN_TIME_LIMITS,
    LoginTimeLimitResponse,
    GlobalLimitsState
>;

export const fetchLoginTimeLimits = ({
    requireAuth = true,
} = {}): FetchLoginTimeLimitsAction => ({
    type: FETCH,
    payload: {
        requestAction: REQUEST_GET_LOGIN_TIME_LIMITS,
        receiveAction: RECEIVE_GET_LOGIN_TIME_LIMITS,
        callApi: call(
            requireAuth ? fetchAuthorized : atgFetch,
            `${RGS_SERVICE_URL}/logintimelimit`,
            {
                method: "GET",
            },
        ),
    },
});

/**
 * PUT LoginTimeLimit API
 *
 */
const transformResponse = (response: any) => {
    const {day, week, month} = response.data.loginTimeLimits;
    return {
        ...response,
        data: {
            day,
            week,
            month,
        },
    };
};

export const REQUEST_PUT_LOGIN_TIME_LIMITS = "limits/REQUEST_PUT_LOGIN_TIME_LIMITS";
export const RECEIVE_PUT_LOGIN_TIME_LIMITS = "limits/RECEIVE_PUT_LOGIN_TIME_LIMITS";

export type PutLoginTimeLimitsAction = FetchAction<
    typeof REQUEST_PUT_LOGIN_TIME_LIMITS,
    typeof RECEIVE_PUT_LOGIN_TIME_LIMITS,
    RgsVerticalLimit,
    GlobalLimitsState
>;

export const putLoginTimeLimits = (
    dailyLimit?: number | null,
    weeklyLimit?: number | null,
    monthlyLimit?: number | null,
): PutLoginTimeLimitsAction => ({
    type: FETCH,
    payload: {
        requestAction: REQUEST_PUT_LOGIN_TIME_LIMITS,
        receiveAction: RECEIVE_PUT_LOGIN_TIME_LIMITS,
        callApi: call(fetchAuthorized, `${RGS_SERVICE_URL}/logintimelimit`, {
            method: "PUT",
            body: JSON.stringify({
                loginTimeLimits: {
                    day: {
                        limit: dailyLimit,
                    },
                    week: {
                        limit: weeklyLimit,
                    },
                    month: {
                        limit: monthlyLimit,
                    },
                },
            }),
        }),
        transformResponse,
    },
});

/**
 * UPDATE LoginTimeLimit
 */
export const UPDATE_LOGIN_TIME_LIMIT = "limits/UPDATE_LOGIN_TIME_LIMIT";

export type UpdateLoginTimeLimit = {
    type: typeof UPDATE_LOGIN_TIME_LIMIT;
    payload: {
        dailyLimit?: PeriodicLimit;
        weeklyLimit?: PeriodicLimit;
        monthlyLimit?: PeriodicLimit;
    };
};

/**
 * SET DepositLimit
 *
 */
export const SET_DEPOSIT_LIMITS = "limits/SET_DEPOSIT_LIMITS";
export const SET_DEPOSIT_LIMITS_SUCCESS = "limits/SET_DEPOSIT_LIMITS_SUCCESS";
export const SET_DEPOSIT_LIMITS_LATER = "limits/SET_DEPOSIT_LIMITS_LATER";

export type SetDepositLimitsAction = {
    type: typeof SET_DEPOSIT_LIMITS;
    payload: DepositBudgetOptions;
};

export type SetDepositLimitsSuccessAction = {
    type: typeof SET_DEPOSIT_LIMITS_SUCCESS;
    payload: MultiPeriodicLimit;
    error?: boolean;
};

export type SetDepositLimitsLaterAction = {
    type: typeof SET_DEPOSIT_LIMITS_LATER;
};

export const setDepositLimits = (
    payload: DepositBudgetOptions,
): SetDepositLimitsAction => ({
    type: SET_DEPOSIT_LIMITS,
    payload,
});

export const setDepositLimitsSuccess = (action: {
    payload: MultiPeriodicLimit;
    error: boolean;
}): SetDepositLimitsSuccessAction =>
    broadcastAction({
        ...action,
        type: SET_DEPOSIT_LIMITS_SUCCESS,
    });

export const setDepositLimitsLater = (): SetDepositLimitsLaterAction =>
    broadcastAction({
        type: SET_DEPOSIT_LIMITS_LATER,
    });

/**
 * GET DepositLimit API
 *
 */
export const REQUEST_GET_DEPOSIT_LIMITS = "limits/REQUEST_GET_DEPOSIT_LIMITS";
export const RECEIVE_GET_DEPOSIT_LIMITS = "limits/RECEIVE_GET_DEPOSIT_LIMITS";

export type FetchDepositLimitAction = FetchAction<
    typeof REQUEST_GET_DEPOSIT_LIMITS,
    typeof RECEIVE_GET_DEPOSIT_LIMITS,
    MultiPeriodicLimit,
    GlobalLimitsState
>;

export const DEPOSIT_LIMITS_URL = `${PAYMENT_URL}/deposit/limits`;

export const fetchDepositLimits = (): FetchDepositLimitAction => ({
    type: FETCH,
    payload: {
        requestAction: REQUEST_GET_DEPOSIT_LIMITS,
        receiveAction: RECEIVE_GET_DEPOSIT_LIMITS,
        callApi: call(fetchAuthorized, DEPOSIT_LIMITS_URL, {
            method: "GET",
        }),
    },
});

/**
 * PUT DepositLimit API
 *
 */
export const REQUEST_PUT_DEPOSIT_LIMITS = "limits/REQUEST_PUT_DEPOSIT_LIMITS";
export const RECEIVE_PUT_DEPOSIT_LIMITS = "limits/RECEIVE_PUT_DEPOSIT_LIMITS";

export type PutDepositLimitsAction = FetchAction<
    typeof REQUEST_PUT_DEPOSIT_LIMITS,
    typeof RECEIVE_PUT_DEPOSIT_LIMITS,
    MultiPeriodicLimit,
    GlobalLimitsState
>;

export const putDepositLimits = ({
    dailyLimit,
    weeklyLimit,
    monthlyLimit,
}: DepositBudgetOptions): PutDepositLimitsAction => ({
    type: FETCH,
    payload: {
        requestAction: REQUEST_PUT_DEPOSIT_LIMITS,
        receiveAction: RECEIVE_PUT_DEPOSIT_LIMITS,
        callApi: call(fetchAuthorized, DEPOSIT_LIMITS_URL, {
            method: "PUT",
            body: JSON.stringify({
                depositLimits: {
                    dailyLimit: {
                        limit: dailyLimit,
                    },
                    weeklyLimit: {
                        limit: weeklyLimit,
                    },
                    monthlyLimit: {
                        limit: monthlyLimit,
                    },
                },
            }),
        }),
    },
});

/**
 *
 */
export type Action =
    | FetchRgsLimitsAction
    | ResetStatusAction
    | LogoutFinishedAction
    | LoginFinishedAction
    | PutLoginTimeLimitsAction
    | UpdateLoginTimeLimit
    | PutDepositLimitsAction
    | FetchDepositLimitAction
    | ReceiveUserAction
    | DeleteRgsLimitsAction
    | ResetRgsFetchStatusAction
    | FetchLoginTimeLimitsAction;

export const netLossLimitActions = [
    REQUEST_GET_RGS_LIMITS,
    RECEIVE_GET_RGS_LIMITS,
    REQUEST_DELETE_RGS_LIMITS,
    RECEIVE_DELETE_RGS_LIMITS,
    RESET_RGS_LIMITS_STATUS,
];
