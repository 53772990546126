import type {
    WinFeedType,
    JackpotType,
    LiveTableType,
} from "@atg-casino-shared/types-feed";
import {
    type ListenWinFeedAction,
    type ReceiveWinFeedAction,
    type ReceiveLiveTableFeedAction,
    type ReceiveJackpotAction,
    type ListenLiveTableFeedAction,
    type StopListenLiveTableFeedAction,
    type StopListenWinFeedAction,
    type ListenJackpotFeedAction,
    type StopListenJackpotFeedAction,
    RECEIVE_WIN_FEED,
    LISTEN_WIN_FEED,
    STOP_LISTENING_TO_WIN_FEED,
    RECEIVE_JACKPOT_FEED,
    LISTEN_JACKPOT_FEED,
    STOP_LISTENING_TO_JACKPOT_FEED,
    RECEIVE_LIVE_TABLE_FEED,
    LISTEN_LIVE_TABLE_FEED,
    STOP_LISTENING_TO_LIVE_TABLE_FEED,
} from "./feed.types";

export const listenWinFeed = (): ListenWinFeedAction => ({
    type: LISTEN_WIN_FEED,
});

export const receiveWinFeed = (payload: WinFeedType[]): ReceiveWinFeedAction => ({
    type: RECEIVE_WIN_FEED,
    payload,
});

export const stopWinFeed = (): StopListenWinFeedAction => ({
    type: STOP_LISTENING_TO_WIN_FEED,
});

export const listenJackpotFeed = (): ListenJackpotFeedAction => ({
    type: LISTEN_JACKPOT_FEED,
});

export const receiveJackpotFeed = (payload: JackpotType[]): ReceiveJackpotAction => ({
    type: RECEIVE_JACKPOT_FEED,
    payload,
});

export const stopJackpotFeed = (): StopListenJackpotFeedAction => ({
    type: STOP_LISTENING_TO_JACKPOT_FEED,
});

export const listenLiveTableFeed = (): ListenLiveTableFeedAction => ({
    type: LISTEN_LIVE_TABLE_FEED,
});

export const receiveLiveTableFeed = (
    payload: LiveTableType[],
): ReceiveLiveTableFeedAction => ({
    type: RECEIVE_LIVE_TABLE_FEED,
    payload,
});

export const stopLiveTableFeed = (): StopListenLiveTableFeedAction => ({
    type: STOP_LISTENING_TO_LIVE_TABLE_FEED,
});
