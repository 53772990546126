import {fetchAuthorized} from "@atg-shared/auth";
import {RGS_SERVICE_URL} from "@atg-shared/service-url";
import type {
    RgsLimits,
    SimplifiedRgsLimits,
} from "@atg-responsible-gambling-shared/limits-types";
import {
    RgsLimitTypeEnum,
    VerticalEnum,
} from "@atg-responsible-gambling-shared/limits-types";

export type Limits = {
    day?: number;
    week?: number;
    month?: number;
};

export type RgsLimitsContext = {
    limitType?: RgsLimitTypeEnum;
    vertical?: VerticalEnum;
    set?: boolean;
};

const formatRgsPeriodic = (limits: Limits) =>
    Object.keys(limits).reduce(
        (a, k) => ({
            ...a,
            [k]: {limit: limits[k as keyof Limits]},
        }),
        {},
    );

const formatRgsLimits = (limits: SimplifiedRgsLimits) => {
    const formatRgsObj: {
        [key: string]: {week: {limit: number}};
    } = {};
    Object.entries(limits).map(([key, value]) => {
        formatRgsObj[key] = {week: {limit: value}};
        return null;
    });
    return formatRgsObj;
};

const getNetLossLimit = (vertical?: VerticalEnum) =>
    fetchAuthorized<RgsLimits>(
        `${RGS_SERVICE_URL}/netlosslimit${vertical ? `/${vertical}` : ""}`,
        {method: "GET"},
    );

export const deleteVerticalRgsLimits = (vertical: VerticalEnum) =>
    fetchAuthorized<RgsLimits>(`${RGS_SERVICE_URL}/netlosslimit/${vertical}`, {
        method: "DELETE",
    });

const getPlayTimeLimit = (vertical?: VerticalEnum) =>
    fetchAuthorized<RgsLimits>(
        `${RGS_SERVICE_URL}/playtimelimit${vertical ? `/${vertical}` : ""}`,
        {method: "GET"},
    );

const getVerticalRgsLimits = (vertical: VerticalEnum = VerticalEnum.CASINO) =>
    fetchAuthorized<RgsLimits>(`${RGS_SERVICE_URL}/${vertical}`, {
        method: "GET",
    });

const setNetLossLimit = (
    vertical?: VerticalEnum,
    loss: Limits | SimplifiedRgsLimits = {},
    abTestVariation = 0,
) => {
    if (Object.keys(loss).includes("week")) {
        return fetchAuthorized<RgsLimits>(`${RGS_SERVICE_URL}/netlosslimit`, {
            method: "PUT",
            body: JSON.stringify({
                [RgsLimitTypeEnum.LOSS]: {
                    [`${vertical}`]: formatRgsPeriodic(loss as Limits),
                },
                abTestVariation,
            }),
        });
    }

    return fetchAuthorized<RgsLimits>(`${RGS_SERVICE_URL}/netlosslimit`, {
        method: "PUT",
        body: JSON.stringify({
            [RgsLimitTypeEnum.LOSS]: formatRgsLimits(loss as SimplifiedRgsLimits),
            abTestVariation,
        }),
    });
};

const setPlayTimeLimit = (vertical: VerticalEnum, time: Limits, abTestVariation = 0) =>
    fetchAuthorized<RgsLimits>(`${RGS_SERVICE_URL}/playtimelimit`, {
        method: "PUT",
        body: JSON.stringify({
            [RgsLimitTypeEnum.TIME]: {[`${vertical}`]: formatRgsPeriodic(time)},
            abTestVariation,
        }),
    });

const setVerticalRgsLimits = (
    vertical: VerticalEnum = VerticalEnum.CASINO,
    loss: Limits,
    time: Limits,
    abTestVariation = 0,
) =>
    fetchAuthorized<RgsLimits>(`${RGS_SERVICE_URL}/${vertical}`, {
        method: "PUT",
        body: JSON.stringify({
            [RgsLimitTypeEnum.LOSS]: {[`${vertical}`]: formatRgsPeriodic(loss)},
            [RgsLimitTypeEnum.TIME]: {[`${vertical}`]: formatRgsPeriodic(time)},
            abTestVariation,
        }),
    });

const fetchRgsLimits = (
    context: Partial<RgsLimitsContext>,
    limits?: {loss?: Limits | SimplifiedRgsLimits; time?: Limits},
    abTestVariation?: number,
) => {
    switch (context.limitType) {
        case RgsLimitTypeEnum.LOSS:
            return limits?.loss
                ? setNetLossLimit(context.vertical, limits.loss, abTestVariation)
                : getNetLossLimit(context.vertical);
        case RgsLimitTypeEnum.TIME:
            return context.vertical && limits?.time
                ? setPlayTimeLimit(context.vertical, limits.time, abTestVariation)
                : getPlayTimeLimit(context.vertical);
        default:
            return limits?.loss && limits.time
                ? setVerticalRgsLimits(
                      context.vertical,
                      limits.loss as Limits,
                      limits.time,
                      abTestVariation,
                  )
                : getVerticalRgsLimits(context.vertical);
    }
};

export default fetchRgsLimits;
