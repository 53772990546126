export enum GAME_TYPE {
    PLAY_FOR_FUN = "PlayForFun",
    PLAY_FOR_REAL = "PlayForReal",
}

export enum GAME_CATEGORY {
    SLOT = "SLOT",
    LIVE_CASINO = "LIVE_CASINO",
    TABLE_GAME = "TABLE_GAME",
    BINGO_GAME = "BINGO_GAME",
    VIRTUAL_GAME = "VIRTUAL_GAME",
}

export enum ERROR_RESPONSES {
    RGS_DEPOSIT_LIMIT_NOT_SET = "RGS_DEPOSIT_LIMIT_NOT_SET",
    RGS_CASINO_LOSS_LIMIT_NOT_SET = "RGS_CASINO_LOSS_LIMIT_NOT_SET",
    RGS_CASINO_PLAY_TIME_LIMIT_NOT_SET = "RGS_CASINO_PLAY_TIME_LIMIT_NOT_SET",
    RGS_CASINO_FULL_PLAY_LIMIT_NOT_SET = "RGS_CASINO_FULL_PLAY_LIMIT_NOT_SET",
    RGS_HIGH_DEPOSIT_LIMIT = "RGS_HIGH_DEPOSIT_LIMIT",
    GEOLOCATION_BLOCKED = "GEOLOCATION_BLOCKED",
    RGS_SELF_EXCLUSION = "RGS_SELF_EXCLUSION",
    RGS_NAR_EXCLUSION = "RGS_NAR_EXCLUSION",
    RGS_NAR_UNDETERMINED = "RGS_NAR_UNDETERMINED",
    RGS_CASINO_LOSS_LIMIT_EXCEEDED = "RGS_CASINO_LOSS_LIMIT_EXCEEDED",
    RGS_CASINO_DAILY_PLAY_TIME_LIMIT_EXCEEDED = "RGS_CASINO_DAILY_PLAY_TIME_LIMIT_EXCEEDED",
    RGS_CASINO_PLAY_TIME_LIMIT_EXCEEDED = "RGS_CASINO_PLAY_TIME_LIMIT_EXCEEDED",
    RGS_COVID_SELF_EXCLUSION = "RGS_COVID_SELF_EXCLUSION",
    RGS_TEMPORARY_SELF_EXCLUSION = "RGS_TEMPORARY_SELF_EXCLUSION",
    INTERNAL_SERVICE_ERROR = "INTERNAL_SERVICE_ERROR",
    INTERNAL_SERVER_ERROR = "INTERNAL_SERVER_ERROR",
    RGS_KYC_QUESTIONNAIRE_NOT_SUBMITTED = "RGS_KYC_QUESTIONNAIRE_NOT_SUBMITTED",
    RGS_KYC_AML_BLOCK = "RGS_KYC_AML_BLOCK",
}

export const RECENT_GAMES = "atg-casino-recently-played-games";

export const STYLE_CONSTANTS = {
    hoverAnimSpeed: "0.6s",
    backgroundZoom: 1.05,
    logoZoom: 1.2,
};
