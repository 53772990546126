import type {SagaIterator} from "redux-saga";
import type {Effect} from "redux-saga/effects";
import {put, fork, throttle, take, race, call, select} from "redux-saga/effects";
import {favoritesSaga} from "@atg-casino-shared/data-access-favorites";
import * as CollectionActions from "@atg-casino-shared/data-access-collection";
import * as PageActions from "@atg-casino-shared/data-access-page";
import * as GameActions from "@atg-casino-shared/data-access-game";
import {
    stopGame,
    getGameType,
    getActiveGame,
    casinoGameSaga,
} from "@atg-casino-shared/data-access-game-flow";
import * as CasinoAnalytics from "@atg-casino-shared/utils-analytics";
import {RESET_ALL} from "@atg-casino-shared/types-root";
import {SelfExclusionActions} from "@atg-responsible-gambling-shared/self-exclusion-domain";
import {SelfExclusionConstants} from "@atg-responsible-gambling-shared/self-exclusion-types";
import {MODAL_SHOW, MODAL_CLOSE, CLOSE_ALL_MODALS} from "atg-modals/modalActionTypes";
import * as ModalActions from "atg-modals/modalActions";
import {LimitsActions} from "@atg-responsible-gambling-shared/limits-domain";

const resetAll = () => ({
    type: RESET_ALL,
});

export function* shouldReset(): SagaIterator {
    yield put(resetAll());
}

export function* modalClosed(modalId: string): SagaIterator {
    let payload;
    let type;
    while (type !== CLOSE_ALL_MODALS && payload !== modalId) {
        ({type, payload} = yield take([MODAL_CLOSE, CLOSE_ALL_MODALS]));
    }
}

export function* setLossLimitModalSaga(): SagaIterator {
    yield put(LimitsActions.resetStatus());

    const {set} = yield race({
        set: take(LimitsActions.REQUEST_GET_RGS_LIMITS),
        closed: call(modalClosed, "casinoLossLimitModal"),
    });

    if (set) {
        yield take(LimitsActions.RECEIVE_GET_RGS_LIMITS);
        yield put(ModalActions.closeCasinoLossLimitModal());
    }
}

export function* panicModalSaga(): SagaIterator {
    yield put(SelfExclusionActions.clearSelfExclusionStatusMessages());
    const gameType = yield select(getGameType);
    const game = yield select(getActiveGame);

    const {set} = yield race({
        set: take(SelfExclusionConstants.REQUEST_PANIC_SELF_EXCLUSION),
        closed: call(modalClosed, "casinoPanicModal"),
    });

    if (set) {
        CasinoAnalytics.selfExclusionConfirm(game, gameType);
        yield put(stopGame());
        yield take(SelfExclusionConstants.RECEIVE_PANIC_SELF_EXCLUSION);
        yield put(ModalActions.closeCasinoPanicModal());
    } else {
        CasinoAnalytics.selfExclusionDismiss(game, gameType);
    }
}

export function* casinoLimitModalsSaga(): SagaIterator {
    while (true) {
        const {payload} = yield take(MODAL_SHOW);

        if (payload === "casinoPanicModal") {
            yield fork(panicModalSaga);
        } else if (payload === "casinoLossLimitModal") {
            yield fork(setLossLimitModalSaga);
        }
    }
}

export function* casinoSaga(): Generator<Effect, void, void> {
    yield throttle(
        10 * 60 * 1000,
        [
            GameActions.REQUEST_GAME,
            GameActions.REQUEST_GAMES,
            PageActions.REQUEST_PAGE,
            PageActions.REQUEST_PAGES,
            CollectionActions.REQUEST_COLLECTION,
        ],
        shouldReset,
    );

    yield fork(casinoLimitModalsSaga);

    yield fork(casinoGameSaga);
    yield fork(casinoLimitModalsSaga);
    yield fork(favoritesSaga);
}
