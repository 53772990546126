export const FAVORITES_ID = "favoriter";
export const COLLECTION_NAME = "Mina favoriter";
export const FAVORITE_GAMES_LIMIT = 30;

export const FAVORITES_CONTEXT = "CASINO_FAVORITE_GAME";
export const FAVORITES_ENDPONT = "/services/tokenized-proxy/cc/api/v1/customization";

export const UPDATE_FAVORITE_GAMES = "UPDATE_FAVORITE_GAMES";

export const TOGGLE_FAVORITE_GAME = "TOGGLE_FAVORITE_GAME";

export const FAVORITE_GAME_DELETED = "FAVORITE_GAME_DELETED";
export const FAVORITE_GAME_ADDED = "FAVORITE_GAME_ADDED";

export const REQUEST_DELETE_GAME = "REQUEST_DELETE_GAME";
export const RECEIVE_DELETE_GAME = "RECEIVE_DELETE_GAME";

export const REQUEST_FAVORITES = "REQUEST_FAVORITES";
export const RECEIVE_FAVORITES = "RECEIVE_FAVORITES";

export const REQUEST_GAME_ADDED = "REQUEST_GAME_ADDED";
export const RECEIVE_GAME_ADDED = "RECEIVE_GAME_ADDED";

export const CLEAR_FAVORITE_GAMES = "CLEAR_FAVORITE_GAMES";
export const TOGGLE_LIMIT_MODAL = "TOGGLE_LIMIT_MODAL";
