import {AGGREGATOR} from "@atg-casino-shared/types-vendor";

// Uncomment the aggregator to run them through launchV2 flow
export const VendorsMigratedToV2 = [
    AGGREGATOR.PRAGMATICPLAY,
    AGGREGATOR.EVERYMATRIX,
    AGGREGATOR.ISOFT,
    AGGREGATOR.SLOTMILL,
    AGGREGATOR.PARIPLAY,
    AGGREGATOR.THUNDERKICK,
    AGGREGATOR.ELK,
    AGGREGATOR.NOLIMITCITY,
    AGGREGATOR.PLAYNGO,
    // AGGREGATOR.EVOLUTION,
    // AGGREGATOR.YGGDRASIL,
    // AGGREGATOR.RELAX,
];
