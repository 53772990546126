import type {FetchAction} from "@atg-shared/fetch-types";
import {call, FETCH} from "@atg-shared/fetch-redux";
import {LOADING_STATUS} from "@atg-shared/fetch-types";
import type {CollectionResponse} from "@atg-casino-shared/types-collection";
import type {GlobalState} from "@atg-casino-shared/types-root";
import * as api from "@atg-casino-shared/utils-api";
import {getLoadingStatus} from "@atg-casino-shared/data-access-helpers";

export const REQUEST_COLLECTION = "casino/REQUEST_COLLECTION";
export const RECEIVE_COLLECTION = "casino/RECEIVE_COLLECTION";

type CollectionContext = {
    collectionId: string;
};

export type FetchCollectionAction = FetchAction<
    typeof REQUEST_COLLECTION,
    typeof RECEIVE_COLLECTION,
    CollectionResponse,
    GlobalState,
    CollectionContext
>;

export const shouldFetchCollection = (collectionId: string) => (state: GlobalState) => {
    const collection = state.casino.collections.entities[collectionId];

    return getLoadingStatus(collection).status !== LOADING_STATUS.OK;
};

export const fetchCollection = (collectionId: string): FetchCollectionAction => ({
    type: FETCH,
    payload: {
        requestAction: REQUEST_COLLECTION,
        receiveAction: RECEIVE_COLLECTION,
        shouldCallApi: call(shouldFetchCollection, collectionId),
        callApi: call(api.fetchCollection, collectionId),
        context: {collectionId},
    },
});

export type CollectionActions = FetchCollectionAction;
