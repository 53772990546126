import type {Reducer} from "redux";
import {createFetchReducer, createInitialState} from "@atg-shared/fetch-redux";
import {
    type DisplayRowResponse,
    COLLECTION_DISPLAY,
    COLLECTIONS_DISPLAY,
    RANDOM_GAME_DISPLAY,
} from "@atg-casino-shared/types-page";
import {type FetchPageAction, RECEIVE_PAGE} from "@atg-casino-shared/data-access-page";
import type {GamesData, GameMin, GamesState} from "@atg-casino-shared/types-game";
import {type FetchTagAction, RECEIVE_TAG} from "@atg-casino-shared/data-access-tag";
import {filteredGameIds} from "@atg-casino-shared/data-access-helpers";
import {
    type FetchCollectionAction,
    RECEIVE_COLLECTION,
} from "@atg-casino-shared/data-access-collection";
import {type ResetAllAction, RESET_ALL} from "@atg-casino-shared/types-root";
import type {FetchGameAction, FetchGamesAction} from "./game.actions";
import {REQUEST_GAME, RECEIVE_GAME, REQUEST_GAMES, RECEIVE_GAMES} from "./game.actions";

export const gamesArrayReducer = (state: GamesData, payload: GameMin[]): GamesData =>
    payload.reduce(
        (a: GamesData, data: GameMin) => ({
            ...a,
            [data.id]: state[data.id]
                ? {...state[data.id], data: {...state[data.id].data, ...data}}
                : createInitialState({data}),
        }),
        state,
    );

const gamesReducer = createFetchReducer(
    REQUEST_GAMES,
    RECEIVE_GAMES,
    RESET_ALL,
    (state, action) =>
        action.type === RECEIVE_GAMES && action.payload
            ? {data: gamesArrayReducer(state.data, action.payload)}
            : state,
    {data: {}},
);

const reducer = createFetchReducer(
    REQUEST_GAME,
    RECEIVE_GAME,
    RESET_ALL,
    (state, action) => {
        if (action.type === RECEIVE_GAME && !action.error) {
            return {
                data: {
                    ...action.payload,
                    relatedGames: filteredGameIds(action.payload.relatedGames),
                    relatedGamesFeature: filteredGameIds(
                        action.payload.relatedGamesFeature || [],
                    ),
                    relatedGamesTheme: filteredGameIds(
                        action.payload.relatedGamesTheme || [],
                    ),
                    fullGame: true,
                },
            };
        }

        return state;
    },
    {},
);

const displayRowToGames = (row: DisplayRowResponse): GameMin[] => {
    switch (row.type) {
        case COLLECTION_DISPLAY:
        case RANDOM_GAME_DISPLAY:
            return row.content.games;
        case COLLECTIONS_DISPLAY:
            return row.content.flatMap(({games}) => games);
        default:
            return [];
    }
};

const pageReducer = (state: GamesData, action: FetchPageAction): GamesData =>
    action.type === RECEIVE_PAGE && !action.error && action.payload.displayRows
        ? gamesArrayReducer(
              state,
              action.payload.displayRows
                  .flatMap(displayRowToGames)
                  .filter((g, i, list) => list.findIndex(({id}) => g.id === id) === i) ||
                  [],
          )
        : state;

const withGamesArrayReducer = (
    state: GamesData,
    action: FetchTagAction | FetchCollectionAction,
): GamesData =>
    (action.type === RECEIVE_COLLECTION || action.type === RECEIVE_TAG) &&
    !action.error &&
    action.payload.games
        ? gamesArrayReducer(state, action.payload.games)
        : state;

const extractRelatedGames = (state: GamesData, action: FetchGameAction): GamesData =>
    action.type === RECEIVE_GAME && !action.error
        ? gamesArrayReducer(state, [
              ...action.payload.relatedGames,
              ...(action.payload.relatedGamesTheme || []),
              ...(action.payload.relatedGamesFeature || []),
          ])
        : state;

const outerGameReducer = (
    state: GamesData,
    action: Extract<FetchGameAction, {type: typeof REQUEST_GAME | typeof RECEIVE_GAME}>,
): GamesData => {
    if (!action.context?.gameId) return state;

    const {gameId} = action.context;

    return {
        ...state,
        ...extractRelatedGames(state, action),
        [gameId]: reducer(state[gameId], action),
    };
};

export const gameReducer: Reducer<
    GamesState,
    | FetchGameAction
    | FetchGamesAction
    | FetchPageAction
    | FetchTagAction
    | FetchCollectionAction
    | ResetAllAction
> = (state = createInitialState({data: {}}), action) => {
    switch (action.type) {
        case REQUEST_GAMES:
        case RECEIVE_GAMES:
            return gamesReducer(state, action);
        case REQUEST_GAME:
        case RECEIVE_GAME:
            return {
                ...state,
                data: outerGameReducer(state.data, action),
            };
        case RECEIVE_PAGE:
            return {...state, data: pageReducer(state.data, action)};
        case RECEIVE_COLLECTION:
        case RECEIVE_TAG:
            return {...state, data: withGamesArrayReducer(state.data, action)};
        case RESET_ALL:
            return gamesReducer(
                {
                    ...state,
                    data: Object.keys(state.data).reduce(
                        (a, k) => ({
                            ...a,
                            [k]: reducer(state.data[k], action),
                        }),
                        {},
                    ),
                },
                action,
            );
        default:
            return state;
    }
};
