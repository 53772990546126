import type {
    JackpotType,
    LiveTableType,
    WinFeedType,
} from "@atg-casino-shared/types-feed";

export const RECEIVE_WIN_FEED = "casino/RECEIVE_WIN_FEED";
export const LISTEN_WIN_FEED = "casino/LISTEN_WIN_FEED";
export const STOP_LISTENING_TO_WIN_FEED = "casino/STOP_LISTENING_TO_WIN_FEED";

export const RECEIVE_JACKPOT_FEED = "casino/RECEIVE_JACKPOT_FEED";
export const LISTEN_JACKPOT_FEED = "casino/LISTEN_JACKPOT_FEED";
export const STOP_LISTENING_TO_JACKPOT_FEED = "casino/STOP_LISTENING_TO_JACKPOT_FEED";

export const RECEIVE_LIVE_TABLE_FEED = "casino/RECEIVE_LIVE_TABLE_FEED";
export const LISTEN_LIVE_TABLE_FEED = "casino/LISTEN_LIVE_TABLE_FEED";
export const STOP_LISTENING_TO_LIVE_TABLE_FEED =
    "casino/STOP_LISTENING_TO_LIVE_TABLE_FEED";

export type ListenJackpotFeedAction = {
    type: typeof LISTEN_JACKPOT_FEED;
};

export type ReceiveJackpotAction = {
    type: typeof RECEIVE_JACKPOT_FEED;
    payload: JackpotType[];
};

export type StopListenJackpotFeedAction = {
    type: typeof STOP_LISTENING_TO_JACKPOT_FEED;
};

export type ListenWinFeedAction = {
    type: typeof LISTEN_WIN_FEED;
};

export type ReceiveWinFeedAction = {
    type: typeof RECEIVE_WIN_FEED;
    payload: WinFeedType[];
};

export type StopListenWinFeedAction = {
    type: typeof STOP_LISTENING_TO_WIN_FEED;
};

export type ListenLiveTableFeedAction = {
    type: typeof LISTEN_LIVE_TABLE_FEED;
};

export type ReceiveLiveTableFeedAction = {
    type: typeof RECEIVE_LIVE_TABLE_FEED;
    payload: LiveTableType[];
};

export type StopListenLiveTableFeedAction = {
    type: typeof STOP_LISTENING_TO_LIVE_TABLE_FEED;
};

export type FeedsAction =
    | ReceiveWinFeedAction
    | ReceiveJackpotAction
    | ReceiveLiveTableFeedAction;
