export enum VENDOR {
    EVOLUTION = "evolution",
    NETENT = "netent",
    PLAYNGO = "playngo",
    YGGDRASIL = "yggdrasil",
    THUNDERKICK = "thunderkick",
    NOLIMITCITY = "nolimitcity",
    ISOFT = "isoft",
    ELK = "elk",
    PRAGMATICPLAY = "pragmaticplay",
    KIRON = "kiron",
    SLOTMILL = "slotmill",
    PARIPLAY = "pariplay",
    QUICKSPIN = "quickspin",
    IRONDOG = "irondog",
    BIGTIMEGAMING = "bigtimegaming",
    SPINBERRY = "spinberry",
    BLUEPRINT = "blueprint",
    SPRIBE = "spribe",
    SPEARHEAD = "spearhead",
    REDTIGER = "redtiger",
    STAKELOGIC = "stakelogic",
    SWINTT = "swintt",
    GAMINGCORPS = "gamingcorps",
    INSPIRED = "inspired",
    RELAX = "relax",
    EVERYMATRIX = "everymatrix",
}

export enum AGGREGATOR {
    NETENT = "netent", // Netent is not an aggregator, should be removed when realising V2 for Evolution
    EVOLUTION = "evolution",
    YGGDRASIL = "yggdrasil",
    PLAYNGO = "playngo",
    NOLIMITCITY = "nolimitcity",
    THUNDERKICK = "thunderkick",
    ISOFT = "isoft",
    ELK = "elk",
    RELAX = "relax",
    PRAGMATICPLAY = "pragmaticplay",
    KIRON = "kiron",
    SLOTMILL = "slotmill",
    PARIPLAY = "pariplay",
    EVERYMATRIX = "everymatrix",
}

export type Vendor = VENDOR;
export type Aggregator = AGGREGATOR;

// to be removed when all vendors migrated to V2
export enum VendorEndpoint {
    EVOLUTION = "evolution/api",
    PLAYNGO = "playngo/api",
    YGGDRASIL = "yggdrasil/api",
    NOLIMITCITY = "nolimitcity/api",
    THUNDERKICK = "thunderkick/api",
    ISOFT = "isoft/api",
    ELK = "elk/api",
    PRAGMATICPLAY = "pragmaticplay/api",
    KIRON = "kiron/api",
    SLOTMILL = "slotmill/api",
    PARIPLAY = "pariplay/api",
    RELAX = "relax/api",
    EVERYMATRIX = "everymatrix/api",
}

export const {PRAGMATICPLAY} = VENDOR;

export const PLAY_FOR_FUN_VENDORS: {[key in VENDOR]: boolean} = {
    [VENDOR.NETENT]: true,
    [VENDOR.PLAYNGO]: true,
    [VENDOR.YGGDRASIL]: true,
    [VENDOR.THUNDERKICK]: true,
    [VENDOR.NOLIMITCITY]: true,
    [VENDOR.ISOFT]: true,
    [VENDOR.ELK]: true,
    [VENDOR.PRAGMATICPLAY]: true,
    [VENDOR.KIRON]: true,
    [VENDOR.SLOTMILL]: true,
    [VENDOR.PARIPLAY]: true,
    [VENDOR.QUICKSPIN]: true,
    [VENDOR.IRONDOG]: true,
    [VENDOR.BIGTIMEGAMING]: true,
    [VENDOR.SPINBERRY]: true,
    [VENDOR.BLUEPRINT]: true,
    [VENDOR.SPRIBE]: true,
    [VENDOR.SPEARHEAD]: true,
    [VENDOR.REDTIGER]: false,
    [VENDOR.STAKELOGIC]: true,
    [VENDOR.SWINTT]: true,
    [VENDOR.GAMINGCORPS]: true,
    [VENDOR.RELAX]: true,
    [VENDOR.EVOLUTION]: false,
    [VENDOR.INSPIRED]: false,
    [VENDOR.EVERYMATRIX]: true,
};
