export type NativeExperienceActionPayload = {
    experienceId: number;
    variation: number;
    fields?: {
        [key: string]: string | number | boolean;
    };
};

export const NATIVE_EXPERIENCE_TRIGGERED = "NATIVE_EXPERIENCE_TRIGGERED";

export type NativeExperienceTriggeredAction = {
    type: typeof NATIVE_EXPERIENCE_TRIGGERED;
    payload: NativeExperienceActionPayload;
};

export type PersonalizationState = {
    nativeExperiences: NativeExperiencesState;
};

export type GlobalPersonalizationState = {
    personalization: PersonalizationState;
};

export type State = GlobalPersonalizationState;
export type Action = NativeExperienceTriggeredAction;

export type NativeExperiencesState = {
    [key: number]: ExperienceReturnValue;
};

export type NativePersonalizationState = {
    nativeExperiences: NativeExperiencesState;
};

export type Experience = {
    /** the ID of the experience (AB test) */
    experienceId: number;

    /** id of each variation (including the control variation) */
    variations: Array<number>;
};

export type ExperienceConfig = {
    dev: Experience;
    prod: Experience;
};

export type ExperiencePayload = {
    payload: {
        [key: string]: string | number | boolean;
    };
    callback: string;
    id: number;
    isControl: boolean;
    variation: number;
};

export type ExperienceResponse = {
    experiencePayloads: ExperiencePayload[];
    contextId: string;
    cookieDomains: string[];
};

export type ExperienceOptions = {
    enabled?: boolean;
};

export type GetVariationOptions = {
    experience: ExperiencePayload;
    variations: Array<number>;
};

export type ExperienceReturnValue = {
    fields?: {
        [key: string]: string | number | boolean;
    };
    variation: number;
};

export type UseAbTestWithRenderPropsType = {
    config: ExperienceConfig;
    children: (props: ExperienceReturnValue) => React.ReactNode;
    options?: ExperienceOptions;
};

export type UseAbTestWithRenderPropsWithTimeoutType = {
    config: ExperienceConfig;
    children: (props: ExperienceReturnValue | null | undefined) => React.ReactNode;
    options?: ExperienceOptions & {
        timeout?: number;
    };
};
