import type {FetchState} from "@atg-shared/fetch-types";
import type {Collection, CollectionResponse} from "@atg-casino-shared/types-collection";
import type {GameMin} from "@atg-casino-shared/types-game";

export enum SCHEDULE_TYPES {
    RECURRING = "Recurring",
    ONCE = "Once",
}

type TimeRange = {startTime: string; endTime: string};

export type OneTimeSchedule = {
    type: SCHEDULE_TYPES.ONCE;
    startTime: number | string;
    endTime?: number | string;
};

export type RecurringSchedule = {
    type: SCHEDULE_TYPES.RECURRING;
    "1"?: TimeRange;
    "2"?: TimeRange;
    "3"?: TimeRange;
    "4"?: TimeRange;
    "5"?: TimeRange;
    "6"?: TimeRange;
    "7"?: TimeRange;
};

export type Schedule = OneTimeSchedule | RecurringSchedule;

export type WithSchedule<T> = {
    schedule: Schedule;
} & T;

export type Articles = Array<{
    __typename?: "Article";
    title: string;
    publishDate: any;
    preamble: string;
    id: string;
    author: {
        __typename?: "Author";
        name: string;
        image: {__typename?: "Asset"; url: string};
        sys: {__typename?: "Sys"; id: string};
    };
    coverImage: {
        __typename?: "Asset";
        url: string;
        width: number;
        height: number;
    };
    tagsCollection: {
        __typename?: "ArticleTagsCollection";
        items: Array<{__typename?: "ArticleTag"; title: string; id: string}>;
    };
    content: {__typename?: "ArticleContent"; json: any};
}>;

export type HeroTitle = {
    text: string;
    isHighlighted: boolean;
};

export type HeroContent = {
    info?: string;
    alignment?: string;
    backgroundImage: string;
    highlightTextColor?: string;
    textContent?: Document;
    primaryTitle?: Array<HeroTitle>;
    secondaryTitle?: Array<HeroTitle> | null;
    teaser?: string;
    cta?: {
        text: string;
        url: string;
    };
};

export type HeroType = {
    mobile?: HeroContent;
    desktop?: HeroContent;
};

export type PageMin = {
    id: string;
    showInMenu?: boolean;
    title?: string | null | undefined;
    icon?: string | null | undefined;
};

export const ARTICLES_DISPLAY = "ArticlesDisplay";
export const COLLECTION_DISPLAY = "Collection";
export const COLLECTIONS_DISPLAY = "CollectionsDisplay";
export const SINGLE_GAME_DISPLAY = "SingleGameDisplay";
export const RANDOM_GAME_DISPLAY = "RandomGameDisplay";

export type SingleGame = {gameId: string; color: string};

type RandomGame = {title: string; description: string; games: []};
type RandomGameResponse = {games: GameMin[]; title: string; description: string};

export type DisplayRow =
    | {
          type: typeof ARTICLES_DISPLAY;
          content: Articles;
      }
    | {
          type: typeof COLLECTION_DISPLAY;
          content: Collection;
      }
    | {
          type: typeof COLLECTIONS_DISPLAY;
          content: Array<Collection>;
      }
    | {
          type: typeof SINGLE_GAME_DISPLAY;
          content: SingleGame;
      }
    | {
          type: typeof RANDOM_GAME_DISPLAY;
          content: RandomGame;
      };

export type DisplayRowResponse =
    | {
          type: typeof ARTICLES_DISPLAY;
          content: Articles;
      }
    | {
          type: typeof COLLECTION_DISPLAY;
          content: CollectionResponse;
      }
    | {
          type: typeof COLLECTIONS_DISPLAY;
          content: Array<CollectionResponse>;
      }
    | {
          type: typeof SINGLE_GAME_DISPLAY;
          content: SingleGame;
      }
    | {
          type: typeof RANDOM_GAME_DISPLAY;
          content: RandomGameResponse;
      };

export type ScheduledHero = WithSchedule<HeroType>;

export type Page = {
    hero?: HeroType | null | undefined;
    articles?: Articles;
    metaTitle: string | null | undefined;
    metaDescription: string | null | undefined;
    displayRows?: Array<DisplayRow>;
    scheduledHeroes: Array<ScheduledHero>;
    description?: string | null | undefined;
} & PageMin;

export type PageData = {
    data: Page | null | undefined;
};

export type PageMeta = {
    title: string | null | undefined;
    description: string | null | undefined;
};

export type PageEntity = FetchState<PageData>;

export type PageList = {
    data: Array<PageMin> | null | undefined;
};

export type PagesState = {
    list: FetchState<PageList>;
    entities: {
        [key: string]: PageEntity;
    };
};

export type PagesResponse = Array<PageMin>;

export type PageResponse = Page & {
    displayRows?: DisplayRowResponse[];
};
