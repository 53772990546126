import type {
    FeedsState,
    LiveTableState,
    LiveTableType,
} from "@atg-casino-shared/types-feed";
import {
    type FeedsAction,
    RECEIVE_WIN_FEED,
    RECEIVE_JACKPOT_FEED,
    RECEIVE_LIVE_TABLE_FEED,
} from "./feed.types";

export const createJackpotKey = (supplier: string, jackpotId: string): string =>
    `${supplier.toLowerCase()}-${jackpotId}`;

export const feedReducer = (
    state: FeedsState = {jackpotFeed: {}, liveTableInfoFeed: {}},
    action: FeedsAction,
) => {
    switch (action.type) {
        case RECEIVE_WIN_FEED:
            return {...state, winFeed: action.payload};
        case RECEIVE_JACKPOT_FEED:
            return {
                ...state,
                jackpotFeed: action.payload.reduce(
                    (a, j) => ({
                        ...a,
                        [createJackpotKey(j.supplier, j.jackpotId)]: j,
                    }),
                    {},
                ),
            };
        case RECEIVE_LIVE_TABLE_FEED:
            return {
                ...state,
                liveTableInfoFeed: action.payload.reduce(
                    (acc: LiveTableState, liveInfo: LiveTableType) => {
                        acc[liveInfo.tableId] = {
                            ...liveInfo,
                            tableIsFull: liveInfo.availableSeats === 0,
                        };
                        return acc;
                    },
                    {},
                ),
            };
        default:
            return state;
    }
};
