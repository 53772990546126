import type {Action} from "redux";
import type {SagaIterator} from "redux-saga";
import {eventChannel} from "redux-saga";
import type {Effect} from "redux-saga/effects";
import {call, take, put, cancelled, race, spawn} from "redux-saga/effects";
import type {
    WinFeedType,
    JackpotType,
    LiveTableType,
} from "@atg-casino-shared/types-feed";
import {subscribe} from "@atg-frame-shared/push";
import {receiveWinFeed, receiveJackpotFeed, receiveLiveTableFeed} from "./feed.actions";
import {
    LISTEN_WIN_FEED,
    STOP_LISTENING_TO_WIN_FEED,
    LISTEN_JACKPOT_FEED,
    STOP_LISTENING_TO_JACKPOT_FEED,
    LISTEN_LIVE_TABLE_FEED,
    STOP_LISTENING_TO_LIVE_TABLE_FEED,
} from "./feed.types";

export const WINFEED_TOPIC = "casinofeed/winfeed/winevent";
export const JACKPOT_TOPIC = "casinofeed/jackpotfeed/activejackpots";
export const LIVE_TABLE_TOPIC = "casinofeed/livetableinfo/evolution";

type NotUndefined = Record<string, unknown> | null;

export const createSubscriptionChannel = async <T extends NotUndefined>(
    topic: string,
) => {
    let handleMessage = (_: T): void => undefined;
    const unsubscribe = await subscribe(topic, (data: T) => handleMessage(data));

    return eventChannel<T>((emit) => {
        handleMessage = emit;
        return unsubscribe;
    });
};

interface StartListener<T> {
    (topic: string, receiveAction: (message: T) => Action): SagaIterator;
}

export function* startListener<T>(
    topic: string,
    receiveAction: (message: T) => Action,
): SagaIterator {
    const subscribeChannel = yield call(createSubscriptionChannel, topic);

    try {
        while (true) yield put(receiveAction(yield take(subscribeChannel)));
    } finally {
        if (cancelled()) subscribeChannel.close();
    }
}

export function* startWinFeed(): SagaIterator {
    while (yield take(LISTEN_WIN_FEED))
        yield race([
            take(STOP_LISTENING_TO_WIN_FEED),
            call<StartListener<WinFeedType[]>>(
                startListener,
                WINFEED_TOPIC,
                receiveWinFeed,
            ),
        ]);
}

export function* startJackpotFeed(): SagaIterator {
    while (yield take(LISTEN_JACKPOT_FEED))
        yield race([
            take(STOP_LISTENING_TO_JACKPOT_FEED),
            call<StartListener<JackpotType[]>>(
                startListener,
                JACKPOT_TOPIC,
                receiveJackpotFeed,
            ),
        ]);
}

export function* startLiveTableFeed(): SagaIterator {
    while (yield take(LISTEN_LIVE_TABLE_FEED))
        yield race([
            take(STOP_LISTENING_TO_LIVE_TABLE_FEED),
            call<StartListener<LiveTableType[]>>(
                startListener,
                LIVE_TABLE_TOPIC,
                receiveLiveTableFeed,
            ),
        ]);
}

export function* feedsListenerSaga(): Generator<Effect, void, void> {
    yield spawn(startWinFeed);
    yield spawn(startJackpotFeed);
    yield spawn(startLiveTableFeed);
}
