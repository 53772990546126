import {type FetchAction, LOADING_STATUS} from "@atg-shared/fetch-types";
import {call, FETCH} from "@atg-shared/fetch-redux";
import type {TagResponse} from "@atg-casino-shared/types-tag";
import type {GlobalState} from "@atg-casino-shared/types-root";
import * as api from "@atg-casino-shared/utils-api";
import {getLoadingStatus} from "@atg-casino-shared/data-access-helpers";

export const REQUEST_TAG = "casino/REQUEST_TAG";
export const RECEIVE_TAG = "casino/RECEIVE_TAG";

export type FetchTagAction = FetchAction<
    typeof REQUEST_TAG,
    typeof RECEIVE_TAG,
    TagResponse,
    GlobalState,
    {id: string}
>;

export const shouldFetchTag = (id: string) => (state: unknown) =>
    getLoadingStatus((state as GlobalState)?.casino?.tags?.entities?.[id]).status !==
    LOADING_STATUS.OK;

export const fetchTag = (id: string): FetchTagAction => ({
    type: FETCH,
    payload: {
        requestAction: REQUEST_TAG,
        receiveAction: RECEIVE_TAG,
        shouldCallApi: call(shouldFetchTag, id),
        callApi: call(api.fetchTag, id),
        context: {id},
    },
});

export type TagActions = FetchTagAction;
