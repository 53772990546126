import type {AtgResponse, FetchAction} from "@atg-shared/fetch-types";
import type {VENDOR} from "@atg-casino-shared/types-vendor";
import {call, FETCH} from "@atg-shared/fetch-redux";
import {LOADING_STATUS} from "@atg-shared/fetch-types";
import * as api from "@atg-casino-shared/utils-api";
import type {GlobalState, ResetAllAction} from "@atg-casino-shared/types-root";
import type {GameResponse, GameMin} from "@atg-casino-shared/types-game";
import {getLoadingStatus} from "@atg-casino-shared/data-access-helpers";

export const REQUEST_GAME = "casino/REQUEST_GAME";
export const RECEIVE_GAME = "casino/RECEIVE_GAME";
export const REQUEST_GAMES = "casino/REQUEST_GAMES";
export const RECEIVE_GAMES = "casino/RECEIVE_GAMES";

type GameContext = {
    gameId: string;
};

export type FetchGameAction = FetchAction<
    typeof REQUEST_GAME,
    typeof RECEIVE_GAME,
    GameResponse,
    GlobalState,
    GameContext
>;

export type FetchGamesAction = FetchAction<
    typeof REQUEST_GAMES,
    typeof RECEIVE_GAMES,
    Array<GameMin>,
    GlobalState
>;

export const shouldFetchGame =
    (gameId: string, force?: boolean) => (state: GlobalState) => {
        if (!force && state.casino.games.data?.[gameId]) {
            const {status} = getLoadingStatus(state.casino.games.data[gameId]);
            return status !== LOADING_STATUS.OK && status !== LOADING_STATUS.LOADING;
        }
        return true;
    };

export const shouldFetchGames =
    (force: boolean | null | undefined) => (state: GlobalState) => {
        if (!force && state?.casino?.games) {
            const {status} = getLoadingStatus(state.casino.games);
            return status !== LOADING_STATUS.OK && status !== LOADING_STATUS.LOADING;
        }
        return true;
    };

export type GameActions = FetchGameAction | FetchGamesAction | ResetAllAction;

const slug = (str: string): string => str.replace(/[^a-zA-Z]/g, "").toLowerCase();

const transformResponse = ({
    data,
    ok,
    ...rest
}: AtgResponse<GameResponse>): AtgResponse<GameResponse> => ({
    ...rest,
    ok,
    data: data.vendorId ? data : {...data, vendorId: slug(data.vendor) as VENDOR},
});

export const fetchGame = (gameId: string, force?: boolean): FetchGameAction => ({
    type: FETCH,
    payload: {
        requestAction: REQUEST_GAME,
        receiveAction: RECEIVE_GAME,
        shouldCallApi: shouldFetchGame(gameId, force),
        callApi: call(api.fetchGame, gameId),
        context: {gameId},
        transformResponse,
    },
});

export const fetchGames = (
    force: boolean | null | undefined = false,
): FetchGamesAction => ({
    type: FETCH,
    payload: {
        requestAction: REQUEST_GAMES,
        receiveAction: RECEIVE_GAMES,
        shouldCallApi: shouldFetchGames(force),
        callApi: call(api.fetchGames),
    },
});
