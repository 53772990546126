import {combineReducers} from "redux";
import type {FetchReducer} from "@atg-shared/fetch-types";
import {createFetchReducer} from "@atg-shared/fetch-redux";
import {
    type PageData,
    type PageList,
    type DisplayRowResponse,
    type PageResponse,
    COLLECTION_DISPLAY,
    COLLECTIONS_DISPLAY,
    RANDOM_GAME_DISPLAY,
} from "@atg-casino-shared/types-page";
import {
    createEntityFetchReducer,
    filteredGameIds,
} from "@atg-casino-shared/data-access-helpers";
import {RESET_ALL} from "@atg-casino-shared/types-root";
import type {FetchPagesAction} from "./page.actions";
import {REQUEST_PAGE, REQUEST_PAGES, RECEIVE_PAGE, RECEIVE_PAGES} from "./page.actions";

const pagesReducer: FetchReducer<PageList, FetchPagesAction> = createFetchReducer(
    REQUEST_PAGES,
    RECEIVE_PAGES,
    RESET_ALL,
    (state: PageList, action: FetchPagesAction) =>
        action.type === RECEIVE_PAGES && !action.error ? {data: action.payload} : state,
    {data: null},
);

const formatDisplayRow = (row: DisplayRowResponse) => {
    switch (row.type) {
        case COLLECTION_DISPLAY:
            return {
                type: COLLECTION_DISPLAY,
                content: {...row.content, games: filteredGameIds(row.content.games)},
            };
        case RANDOM_GAME_DISPLAY:
            return {
                type: RANDOM_GAME_DISPLAY,
                content: {...row.content, games: filteredGameIds(row.content.games)},
            };
        case COLLECTIONS_DISPLAY:
            return {
                type: COLLECTIONS_DISPLAY,
                content: row.content.map(({games, ...rest}) => ({
                    ...rest,
                    games: filteredGameIds(games),
                })),
            };
        default:
            return row;
    }
};

const formatDisplayRows = (rows: DisplayRowResponse[]) => rows.map(formatDisplayRow);

const reducer = createFetchReducer<
    PageData,
    PageResponse,
    typeof REQUEST_PAGE,
    typeof RECEIVE_PAGE,
    typeof RESET_ALL,
    {pageId: string}
>(
    REQUEST_PAGE,
    RECEIVE_PAGE,
    RESET_ALL,
    (state, action) =>
        action.type === RECEIVE_PAGE && !action.error
            ? {
                  data: {
                      ...state.data,
                      ...action.payload,
                      displayRows: formatDisplayRows(action.payload.displayRows || []),
                  },
              }
            : state,
    {data: null},
);

export const pageReducer = combineReducers({
    list: pagesReducer,
    entities: createEntityFetchReducer(
        REQUEST_PAGE,
        RECEIVE_PAGE,
        RESET_ALL,
        reducer,
        "pageId",
    ),
});
