import {combineReducers} from "redux";
import type {FetchReducer} from "@atg-shared/fetch-types";
import {createFetchReducer} from "@atg-shared/fetch-redux";
import type {TagsState, Tag} from "@atg-casino-shared/types-tag";
import {
    createEntityFetchReducer,
    filteredGameIds,
} from "@atg-casino-shared/data-access-helpers";
import {type FetchTagAction, REQUEST_TAG, RECEIVE_TAG} from "./tag.actions";

export const initialState: TagsState = {
    entities: {},
};

const reducer: FetchReducer<{data: Tag | null | undefined}, FetchTagAction> =
    createFetchReducer(
        REQUEST_TAG,
        RECEIVE_TAG,
        "",
        (state: {data: Tag | null | undefined}, action: FetchTagAction) =>
            action.type === RECEIVE_TAG && !action.error && action.payload
                ? {
                      data: {
                          ...action.payload,
                          games: filteredGameIds(action.payload.games),
                      },
                  }
                : state,
        {data: null},
    );

export const tagReducer = combineReducers({
    entities: createEntityFetchReducer(REQUEST_TAG, RECEIVE_TAG, null, reducer, "id"),
});
