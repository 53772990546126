import {createSelector} from "reselect";
import {device} from "@atg/utils";
import type {Vendor} from "@atg-casino-shared/types-vendor";
import type {Game} from "@atg-casino-shared/types-game";
import {isLargeScreen} from "atg-match-media/domain/matchMediaSelectors";
import type {State as MatchMediaGlobalState} from "atg-match-media/domain";
import type {GlobalState, GameState, GameType} from "./game.flow.types";

type Selector<T> = (state: GlobalState) => T;

const getGameState = (state: GlobalState): GameState | null | undefined =>
    state?.casino?.game;

export const isLaunchDataLoading: Selector<boolean> = createSelector(
    getGameState,
    (gameState: GameState | null | undefined): boolean =>
        Boolean(gameState?.game && !gameState.error && !gameState.launchData),
);

export const getActiveGameError: Selector<string | null | undefined> = createSelector(
    getGameState,
    (state: GameState | null | undefined): string | null | undefined => state?.error,
);

export const getLaunchUrl: Selector<string | null | undefined> = createSelector(
    getGameState,
    (state: GameState | null | undefined): string | null | undefined => state?.launchData,
);

export const getGameType: Selector<GameType | null | undefined> = createSelector(
    getGameState,
    (state: GameState | null | undefined): GameType | null | undefined => state?.gameType,
);

export const getActiveGame: Selector<Game | null | undefined> = createSelector(
    getGameState,
    (state: GameState | null | undefined): Game | null | undefined => state?.game,
);

export const getVendorId: Selector<Vendor | null | undefined> = createSelector(
    getActiveGame,
    (game: Game | null | undefined): Vendor | null | undefined => game && game.vendorId,
);

export const getVendorGameId: Selector<string | null | undefined> = createSelector(
    getActiveGame,
    (game: Game | null | undefined): string | null | undefined =>
        game && game.vendorGameId,
);

export const getBackgroundUrl: Selector<string | null | undefined> = createSelector(
    getActiveGame,
    (game: Game | null | undefined): string | null | undefined =>
        game && game.background && game.background.xl,
);

export const isGameFrameLoaded: Selector<boolean> = createSelector(
    getGameState,
    (state: GameState | null | undefined): boolean => Boolean(state?.frameLoaded),
);

export const getRemoteGameLink: Selector<string | undefined> = createSelector(
    getGameState,
    (state: GameState | null | undefined): string | undefined => state?.remoteLink,
);

export const isGameFrameLoading: Selector<boolean> = createSelector(
    getLaunchUrl,
    isGameFrameLoaded,
    (launchUrl: string | null | undefined, frameLoaded: boolean): boolean =>
        Boolean(launchUrl && !frameLoaded),
);

export const isGameLoading: Selector<boolean> = createSelector(
    getActiveGame,
    isGameFrameLoaded,
    (activeGame: Game | null | undefined, loaded: boolean): boolean =>
        Boolean(activeGame && !loaded),
);

export const getLoadingGameType: Selector<GameType | null | undefined> = createSelector(
    getGameType,
    isGameLoading,
    (
        gameType: GameType | null | undefined,
        loading: boolean,
    ): GameType | null | undefined => (loading ? gameType : null),
);

export const isSmallDevice: (state: GlobalState & MatchMediaGlobalState) => boolean =
    createSelector(
        isLargeScreen,
        device.isDevice,
        (largeScreen, isDevice) => !largeScreen && isDevice,
    );

export const isCasinoTakeOver: (state: GlobalState & MatchMediaGlobalState) => boolean =
    createSelector(
        isGameFrameLoaded,
        isSmallDevice,
        (loaded: boolean | null | undefined, smol: boolean): boolean =>
            Boolean(loaded && smol),
    );
