import type {Selector} from "reselect";
import type {CollectionsState} from "@atg-casino-shared/types-collection";
import type {FeedsState} from "@atg-casino-shared/types-feed";
import type {PagesState} from "@atg-casino-shared/types-page";
import type {GamesState} from "@atg-casino-shared/types-game";
import type {MenuState} from "@atg-casino-shared/types-menu";
import type {TagsState} from "@atg-casino-shared/types-tag";
import type {FavoritesState} from "@atg-casino-shared/types-favorites";

export type CasinoSelector<T> = Selector<GlobalState, T>;

export type CasinoState = {
    menu: MenuState;
    pages: PagesState;
    games: GamesState;
    collections: CollectionsState;
    tags: TagsState;
    feeds: FeedsState;
    favorites: FavoritesState;
};

export type GlobalState = {
    casino: CasinoState;
};

type LocationStateProps = "gameId" | "pageId" | "collectionId" | "tagId";

export type LocationState = {
    [K in LocationStateProps]?: string;
} & {
    isRecentGame?: boolean;
};

export const RESET_ALL = "casino/RESET_ALL";

export type ResetAllAction = {
    type: typeof RESET_ALL;
};
