import {AGGREGATOR} from "@atg-casino-shared/types-vendor";
import {type Game, GAME_CATEGORY} from "@atg-casino-shared/types-game";
import {
    getOriginRoot,
    getBaseV2Url,
    getAccessChannel,
    getDeviceType,
} from "./launch.helpers";

interface UrlBuilderV2Props {
    game: Game;
    playForReal: boolean;
    endpoint: "private" | "public";
}

// the originRoot parameter needs to be appended last to the URL;
// otherwise, it will break the generic fetchAuthorized() call inside game.flow.saga.ts.
export const getLaunchUrlV2 = ({
    game,
    playForReal,
    endpoint,
}: UrlBuilderV2Props): string => {
    const originRoot = getOriginRoot();
    const {aggregateId, vendorGameId, type} = game;
    const baseUrl = getBaseV2Url(endpoint === "private", aggregateId);

    const gameCategory = type ?? GAME_CATEGORY.SLOT;

    let additionalParams = `?vendorGameId=${vendorGameId}&accessChannel=${getAccessChannel()}&deviceType=${getDeviceType()}&channel=${getDeviceType()}`;

    switch (aggregateId) {
        case AGGREGATOR.EVOLUTION:
            {
                const {tableId} = game;

                additionalParams += `&playMode=${
                    playForReal ? "real_money" : "play_for_fun"
                }&tableId=${tableId}&gameType=${gameCategory}&channel=${getDeviceType()}`;
            }

            break;

        case AGGREGATOR.PRAGMATICPLAY:
            additionalParams += `&gameCategory=${gameCategory}&originRoot=${originRoot}`;

            break;

        case AGGREGATOR.PLAYNGO:
            additionalParams += `&originRoot=${originRoot}`;

            break;

        case AGGREGATOR.EVERYMATRIX: {
            const {tableId} = game;

            additionalParams += `&tableId=${tableId}&originRoot=${originRoot}`;

            break;
        }

        default:
            break;
    }

    const url = baseUrl + additionalParams;

    return url;
};
