import type {FavoriteGame, FavoritesState} from "@atg-casino-shared/types-favorites";
import type {FavoritesAction} from "./favorites.types";
import * as constants from "./favorites.constants";

const initialState: FavoritesState = {
    games: {},
    limitModalVisible: false,
    isLoading: false,
    isError: false,
};

export const favoritesReducer = (
    state = initialState,
    action: FavoritesAction,
): FavoritesState => {
    switch (action.type) {
        case constants.REQUEST_FAVORITES: {
            return {...state, isLoading: true, isError: false};
        }
        case constants.RECEIVE_FAVORITES: {
            if (action.error) {
                return {...state, isError: true, isLoading: false};
            }
            if (!action.payload.length) {
                return {...state, isError: false, isLoading: false};
            }

            const games = action.payload.reduce(
                (acc, res) => {
                    const parsed = JSON.parse(res.data);
                    acc[parsed.gameId] = {...parsed, id: res.id};
                    return acc;
                },
                {...state.games},
            );

            return {...state, games, isLoading: false, isError: false};
        }
        case constants.REQUEST_GAME_ADDED: {
            if (!action.context) return state;

            return {
                ...state,
                games: {...state.games, [action.context.gameId]: {...action.context}},
            };
        }
        case constants.RECEIVE_GAME_ADDED: {
            if (!action.context) return state;

            if (action.error) {
                const games = {...state.games};
                delete games[action.context.gameId];

                return {...state, games};
            }

            if (!action.payload) return state;

            const game = JSON.parse(action.payload.data) as FavoriteGame;

            return {
                ...state,
                games: {...state.games, [game.gameId]: {...game, id: action.payload.id}},
            };
        }
        case constants.REQUEST_DELETE_GAME: {
            if (!action.context) return state;

            const games = {...state.games};
            delete games[action.context.gameId];

            return {...state, games};
        }
        case constants.RECEIVE_DELETE_GAME: {
            if (!action.context) return state;

            if (action.error) {
                const game = action.context;
                return {...state, games: {...state.games, [game.gameId]: game}};
            }

            const games = {...state.games};
            delete games[action.context.gameId];

            return {...state, games};
        }
        case constants.CLEAR_FAVORITE_GAMES: {
            return initialState;
        }
        case constants.TOGGLE_LIMIT_MODAL: {
            return {...state, limitModalVisible: action.payload.visible};
        }
        default:
            return state;
    }
};
