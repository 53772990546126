import {type FetchAction, LOADING_STATUS} from "@atg-shared/fetch-types";
import {call, FETCH} from "@atg-shared/fetch-redux";
import type {GlobalState, ResetAllAction} from "@atg-casino-shared/types-root";
import * as api from "@atg-casino-shared/utils-api";
import {getLoadingStatus} from "@atg-casino-shared/data-access-helpers";
import type {PageResponse, PagesResponse} from "@atg-casino-shared/types-page";

export const REQUEST_PAGE = "casino/REQUEST_PAGE";
export const RECEIVE_PAGE = "casino/RECEIVE_PAGE";
export const REQUEST_PAGES = "casino/REQUEST_PAGES";
export const RECEIVE_PAGES = "casino/RECEIVE_PAGES";

type PageContext = {
    pageId: string;
};

export type FetchPageAction = FetchAction<
    typeof REQUEST_PAGE,
    typeof RECEIVE_PAGE,
    PageResponse,
    GlobalState,
    PageContext
>;

export type FetchPagesAction = FetchAction<
    typeof REQUEST_PAGES,
    typeof RECEIVE_PAGES,
    PagesResponse,
    GlobalState
>;

const shouldFetchPages = (state: unknown) =>
    getLoadingStatus((state as GlobalState)?.casino?.pages?.list).status !==
    LOADING_STATUS.OK;

export const shouldFetchPage = (pageId: string) => (state: GlobalState) => {
    const page = state.casino.pages.entities[pageId];

    return getLoadingStatus(page).status !== LOADING_STATUS.OK;
};

export const fetchPage = (pageId: string): FetchPageAction => ({
    type: FETCH,
    payload: {
        requestAction: REQUEST_PAGE,
        receiveAction: RECEIVE_PAGE,
        shouldCallApi: call(shouldFetchPage, pageId),
        callApi: call(api.fetchPage, pageId),
        context: {pageId},
    },
});

export const fetchPages = (): FetchPagesAction => ({
    type: FETCH,
    payload: {
        requestAction: REQUEST_PAGES,
        receiveAction: RECEIVE_PAGES,
        shouldCallApi: shouldFetchPages,
        callApi: call(api.fetchPages),
    },
});

export type PageActions = FetchPagesAction | FetchPageAction | ResetAllAction;
