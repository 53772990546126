import {has} from "lodash";
import {createSelector} from "reselect";
import type {AuthorizedUser} from "./user.types";
import type {State} from "./index";

export const getUser = (state: State) => state.user as AuthorizedUser;

export const getAuthorizedUser = (state: State) => {
    if (!state.user.loggedIn) throw new Error("User needs to be authorized");
    return state.user as AuthorizedUser;
};

/**
 * This selector will return true if the user has logged in at some point without actively logging out afterwards (ie did not press log out, and was not logged out by some functionality)
 *
 * If it returns "false", it is reliable - user is not logged in
 *
 * If it returns "true", it is not reliable - user has logged in at some point (and had a highest scope - "betting" user), but the scope might have been downgraded to "general" user already.
 * It is also possible that "refresh" token is still valid, and "access" token could be refreshed without a login (although perhaps with a lower scope, aka "general/cookie" user). But it is also possible that even "refresh" token is expired, thus a user is not logged in and will not be able to do protected requests until logged in.
 *
 * Main difference from AuthSelectors.isLoggedIn is that the return value will be true even after e.g. checkAuth() request irregardless of the current scope that the user has
 *
 * See function fetchAuthorized that can make a request and also trigger login prompt if the request is unauthorized
 *
 * More documentation on our auth flow - https://developer.atg.se/frontend/authentication/authentication.html
 *
 * TODO: a more proper name for this function could be wasLoggedIn, since there is no guarantee that one still is.
 */
export const isLoggedIn = (state: State) => getUser(state).loggedIn;

export const isProtectedIdentity = (state: State) => getUser(state).protectedIdentity;

export const getRememberUserName = (state: State) => getUser(state).rememberUsername;

export const getFirstName = createSelector(
    (state: State) => {
        try {
            return getAuthorizedUser(state);
        } catch (e: unknown) {
            return null;
        }
    },
    (user) => user && user.firstName,
);
export const getBalanceAmount = (state: State) => {
    const user = getUser(state) as AuthorizedUser;
    if (!user) return null;
    return user.balance ? user.balance.amount : null;
};
export const isReturningUser = (state: State) => has(getUser(state), "username");

export const isVerificationCodeActive = (state: State) =>
    getUser(state).verificationCodeActive;

export const isLowBalance = (state: State, betCost: number) => {
    const balance = getBalanceAmount(state);
    if (balance === null || !betCost) return false;
    return betCost > balance;
};

export const shouldConfirmWithPassword = (state: State) =>
    getUser(state) ? getUser(state).verificationCodeActive : false;

export const getBalance = (state: State) => {
    const user = getUser(state);
    return user && user.loggedIn && user.balance ? user.balance : null;
};

export const isBalanceHidden = (state: State) => {
    const user = getUser(state);
    if (!user) return true;

    return user.showBalance !== true;
};

export const getUsername = (state: State) => state.user.username;

export const isLoading = (state: State) =>
    state && state.user ? state.user.loading : false;

export const getAvatarUrl = (state: State) => {
    const user = getUser(state);
    if (!user || !user.loggedIn) return null;
    return user.avatarRef || null;
};

export const isMale = (state: State) => {
    const user = getUser(state);
    if (!user || !user.loggedIn) return false;
    return user.sex === "male";
};

export const isMissingContactInfo = (state: State) => {
    if (
        state.user.contactInfo &&
        (state.user.contactInfo.email || state.user.contactInfo.mobilePhone)
    )
        return false;
    return true;
};

export const getCustomerId = (state: State) => state.user.customerId;

export const getNextPossibleLogin = createSelector(
    getUser,
    (user) => user.nextPossibleLogin,
);
