import {combineReducers} from "redux";
import {selfExclusion} from "@atg-responsible-gambling-shared/self-exclusion-domain";
import {casinoReducer} from "@atg-casino-shared/data-access-root-reducer";
import personalization from "@atg-global-shared/personalization/domain/personalizationReducer";
import {limitsReducer as limits} from "@atg-responsible-gambling-shared/limits-domain";
import {modals} from "atg-modals/modalReducer";
import {activeBreakpoint} from "atg-match-media/domain/matchMediaReducer";

const createRootReducer = () =>
    combineReducers({
        activeBreakpoint,
        accessToken: () => window._frameStore.getState().accessToken,
        auth: () => window._frameStore.getState().auth,
        limits,
        modals,
        personalization,
        realityCheck: () => window._frameStore.getState().realityCheck, // Verify if we could move it to frame
        router: () => window._frameStore.getState().router,
        responsibleGamingHeader: () =>
            window._frameStore.getState().responsibleGamingHeader, // Verify if we could move it to frame
        user: () => window._frameStore.getState().user,
        casino: casinoReducer,
        selfExclusion,
    });

export default createRootReducer;
