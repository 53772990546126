import {VENDOR, VendorEndpoint} from "@atg-casino-shared/types-vendor";
import {type Game, type GameType, GAME_CATEGORY} from "@atg-casino-shared/types-game";
import {
    getLaunchUrl,
    getYggdrasilUrl,
    getPlayNGoUrl,
    getIsoftUrl,
    getElkUrl,
    getKironUrl,
    getSlotmillUrl,
    getPariplayUrl,
    getEvolutionLaunchParams,
    getRelaxUrl,
} from "../helpers/helpers";

interface VendorConfigProps {
    game: Game;
    gameType?: GameType;
    launchData?: string;
}

export const getVendorConfig = async ({
    game,
    gameType,
    launchData,
}: VendorConfigProps) => {
    const {vendorGameId, startParams, type} = game;
    const gameCategory = type ?? GAME_CATEGORY.SLOT;

    switch (game.vendorId) {
        case VENDOR.NETENT:
        case VENDOR.REDTIGER:
        case VENDOR.BIGTIMEGAMING:
        case VENDOR.EVOLUTION: {
            const params = getEvolutionLaunchParams(game, gameType);

            const launchUrl = getLaunchUrl({
                endpoint: VendorEndpoint.EVOLUTION,
                vendorGameId,
                category: gameCategory,
                params,
            });

            return {
                gameUrl: launchData,
                launchUrl,
            };
        }
        case VENDOR.YGGDRASIL: {
            const launchUrl = getLaunchUrl({
                endpoint: VendorEndpoint.YGGDRASIL,
                vendorGameId,
                category: gameCategory,
            });

            const gameUrl = getYggdrasilUrl({
                vendorGameId,
                launchData: launchData ?? launchUrl,
                gameType,
            });

            return {
                gameUrl,
                launchUrl,
            };
        }

        case VENDOR.PLAYNGO: {
            const launchUrl = getLaunchUrl({
                endpoint: VendorEndpoint.PLAYNGO,
                vendorGameId,
                category: gameCategory,
            });

            const gameUrl = getPlayNGoUrl({
                vendorGameId,
                launchData: launchData ?? launchUrl,
                gameType,
            });

            return {
                gameUrl,
                launchUrl,
            };
        }
        case VENDOR.NOLIMITCITY: {
            const launchUrl = getLaunchUrl({
                endpoint: VendorEndpoint.NOLIMITCITY,
                vendorGameId,
                category: gameCategory,
            });

            return {
                gameUrl: launchData ?? launchUrl,
                launchUrl,
            };
        }
        case VENDOR.THUNDERKICK: {
            const launchUrl = getLaunchUrl({
                endpoint: VendorEndpoint.THUNDERKICK,
                vendorGameId,
                category: gameCategory,
            });

            return {
                gameUrl: launchData ?? launchUrl,
                launchUrl,
            };
        }
        case VENDOR.GAMINGCORPS:
        case VENDOR.SPEARHEAD:
        case VENDOR.SPRIBE:
        case VENDOR.ISOFT: {
            const launchUrl = getLaunchUrl({
                endpoint: VendorEndpoint.ISOFT,
                vendorGameId,
                category: gameCategory,
            });

            const gameUrl = getIsoftUrl({
                vendorGameId,
                launchData: launchData ?? launchUrl,
                gameType,
            });

            return {
                gameUrl,
                launchUrl,
            };
        }
        case VENDOR.ELK: {
            const launchUrl = getLaunchUrl({
                endpoint: VendorEndpoint.ELK,
                vendorGameId,
                category: gameCategory,
            });

            const gameUrl = getElkUrl({
                vendorGameId: game.vendorGameId,
                launchData: launchData ?? launchUrl,
                gameType,
            });

            return {
                gameUrl,
                launchUrl,
            };
        }
        case VENDOR.RELAX: {
            const launchUrl = getLaunchUrl({
                endpoint: VendorEndpoint.RELAX,
                vendorGameId,
                category: gameCategory,
            });

            const gameUrl = await getRelaxUrl({
                vendorGameId: game.vendorGameId,
                launchData: launchData ?? launchUrl,
                gameType,
            });
            return {
                gameUrl,
                launchUrl,
            };
        }
        case VENDOR.KIRON: {
            const launchUrl = getLaunchUrl({
                endpoint: VendorEndpoint.KIRON,
                vendorGameId,
                category: GAME_CATEGORY.VIRTUAL_GAME,
            });

            const gameUrl = getKironUrl({
                vendorGameId,
                launchData: launchData ?? launchUrl,
                gameType,
                startParams: game.startParams,
            });

            return {
                gameUrl,
                launchUrl,
            };
        }
        case VENDOR.SLOTMILL: {
            const launchUrl = getLaunchUrl({
                endpoint: VendorEndpoint.SLOTMILL,
                vendorGameId,
                category: gameCategory,
            });

            const gameUrl = getSlotmillUrl({
                vendorGameId,
                launchData: launchData ?? launchUrl,
                gameType,
                startParams,
            });

            return {
                gameUrl,
                launchUrl,
            };
        }
        case VENDOR.BLUEPRINT:
        case VENDOR.SPINBERRY:
        case VENDOR.QUICKSPIN:
        case VENDOR.IRONDOG:
        case VENDOR.INSPIRED:
        case VENDOR.STAKELOGIC:
        case VENDOR.SWINTT:
        case VENDOR.PARIPLAY: {
            const launchUrl = getLaunchUrl({
                endpoint: VendorEndpoint.PARIPLAY,
                vendorGameId,
                category: gameCategory,
            });

            const gameUrl = getPariplayUrl({
                vendorGameId,
                launchData: launchData ?? launchUrl,
                gameType,
                startParams,
            });

            return {
                gameUrl,
                launchUrl,
            };
        }

        default:
            return {gameUrl: "", launchUrl: ""};
    }
};
