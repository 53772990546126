import {combineReducers} from "redux";
import {createFetchReducer} from "@atg-shared/fetch-redux";
import type {CollectionData} from "@atg-casino-shared/types-collection";
import {
    createEntityFetchReducer,
    filteredGameIds,
} from "@atg-casino-shared/data-access-helpers";
import type {FetchCollectionAction} from "./collection.actions";
import {RECEIVE_COLLECTION, REQUEST_COLLECTION} from "./collection.actions";

const reducer = createFetchReducer(
    REQUEST_COLLECTION,
    RECEIVE_COLLECTION,
    "",
    (state: CollectionData, action: FetchCollectionAction) =>
        action.type === RECEIVE_COLLECTION && !action.error
            ? {
                  data: {
                      ...state.data,
                      ...action.payload,
                      games: filteredGameIds(action.payload.games),
                  },
              }
            : state,
    {data: null},
);

export const collectionReducer = combineReducers({
    entities: createEntityFetchReducer(
        REQUEST_COLLECTION,
        RECEIVE_COLLECTION,
        null,
        reducer,
        "collectionId",
    ),
});
