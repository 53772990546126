export const REQUEST_SELF_EXCLUSION = "REQUEST_SELF_EXCLUSION";
export const RECEIVE_SELF_EXCLUSION = "RECEIVE_SELF_EXCLUSION";

export const REQUEST_SET_SELF_EXCLUSION = "REQUEST_SET_SELF_EXCLUSION";
export const RECEIVE_SET_SELF_EXCLUSION = "RECEIVE_SET_SELF_EXCLUSION";

export const CLEAR_SELF_EXCLUSION_STATUS_MESSAGES =
    "CLEAR_SELF_EXCLUSION_STATUS_MESSAGES";

export const REQUEST_PANIC_SELF_EXCLUSION = "REQUEST_PANIC_SELF_EXCLUSION";
export const RECEIVE_PANIC_SELF_EXCLUSION = "RECEIVE_PANIC_SELF_EXCLUSION";
