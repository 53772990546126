import root from "window-or-global";
import {get} from "lodash";
import {device} from "@atg/utils";
import {isApp} from "@atg-shared/system";
import {PLAY_FOR_FUN_VENDORS, VENDOR} from "@atg-casino-shared/types-vendor";
import {
    type Game,
    type GameCategory,
    type GameType,
    GAME_CATEGORY,
    GAME_TYPE,
} from "@atg-casino-shared/types-game";
import {pureFetch} from "@atg-shared/fetch";

type GlobalClientConfig = typeof root & {
    clientConfig?: {
        env: string;
    };
};

const environment = (root as GlobalClientConfig)?.clientConfig?.env ?? "qa";

type EvolutionParams = {
    tableId?: string;
    mobileVirtualTableId?: string;
};

export const getEvolutionLaunchParams = (game: Game, gameType?: GameType) => {
    const mobile = device.isDevice();

    const startParams = game.startParams ?? "";

    const tableIds = startParams
        .split("&")
        .filter((p) => p)
        .reduce(
            (a, pair) => ({
                ...a,
                [pair.split("=")[0]]: pair.split("=")[1],
            }),
            {} as EvolutionParams,
        );

    const params =
        mobile && tableIds.mobileVirtualTableId
            ? tableIds.mobileVirtualTableId
            : tableIds.tableId;

    return `&tableId=${params ?? startParams}&mode=${
        gameType === GAME_TYPE.PLAY_FOR_FUN ? "play_for_fun" : "real_money"
    }`;
};

const {PLAY_FOR_REAL, PLAY_FOR_FUN} = GAME_TYPE;

type ScrProps = {
    vendorGameId: string;
    launchData: string;
    gameType?: GameType;
    type?: GameCategory;
    startParams?: string | null;
};

export const getYggdrasilConfig = () => ({
    baseUrl: get(root, "clientConfig.casino.vendor.yggdrasilUrl"),
    playForFunBaseUrl:
        "https://staticpffse.yggdrasilgaming.com/init/launchClient.html?org=ATGCasino&lang=sv&currency=SEK",
});

export const getPlayNGoConfig = () => ({
    baseUrl: get(root, "clientConfig.casino.vendor.playNGoUrl"),
});

export const getIsoftConfig = () => ({
    baseUrl: get(root, "clientConfig.casino.vendor.isoftUrl"),
});

export const getElkConfig = () => ({
    baseUrl: get(root, "clientConfig.casino.vendor.elkUrl"),
});

export const getKironConfig = () => ({
    baseUrl: get(root, "clientConfig.casino.vendor.kiron.url"),
});

export const getBigsConfig = () => ({
    baseUrl: get(root, "clientConfig.casino.vendor.kiron.bigs.url"),
    operator: get(root, "clientConfig.casino.vendor.kiron.bigs.operator"),
});

export const getBigsUrl = ({vendorGameId, launchData, gameType}: ScrProps) => {
    const {baseUrl, operator} = getBigsConfig();

    const operatorId = `o=${operator}`;
    const playerSessionId = `p=${launchData}`;
    const exclusiveGameId = `eg=${vendorGameId}`;
    const languagueCode = "l=sv";
    const currencyCode = "c=SEK";
    const demoPlay = `dp=${gameType === PLAY_FOR_REAL ? "0" : "1"}`;

    return `${baseUrl}/?${operatorId}&${playerSessionId}&${exclusiveGameId}&${languagueCode}&${currencyCode}&${demoPlay}`;
};

export const getKironUrl = ({
    vendorGameId,
    launchData,
    gameType,
    startParams,
}: ScrProps) => {
    if (startParams === "BIGS") {
        return getBigsUrl({vendorGameId, launchData, gameType});
    }

    const {baseUrl} = getKironConfig();
    const gameId = `dg=${vendorGameId}`;
    const playForFun = `dp=${gameType === PLAY_FOR_REAL ? "0" : "1"}`;
    const key = `p=${gameType === PLAY_FOR_REAL ? launchData : ""}`;

    return `${baseUrl}&c=SEK&l=sv&${gameId}&${playForFun}&${key}`;
};

export const getYggdrasilUrl = ({vendorGameId, launchData, gameType}: ScrProps) => {
    const {baseUrl, playForFunBaseUrl} = getYggdrasilConfig();
    const gameId = `gameid=${vendorGameId}`;
    const channel = `channel=${device.isDevice() ? "mobile" : "pc"}`;
    const key = `key=${gameType === PLAY_FOR_REAL ? launchData : ""}`;

    return `${
        gameType === PLAY_FOR_REAL ? baseUrl : playForFunBaseUrl
    }&${gameId}&${channel}&${key}`;
};

export const getPlayNGoUrl = ({vendorGameId, launchData, gameType}: ScrProps) => {
    const {baseUrl} = getPlayNGoConfig();
    const isDevice = device.isDevice();

    const gid = `gid=${vendorGameId}${isDevice ? "mobile" : ""}`;
    const user = `${isDevice ? "ticket" : "user"}${
        gameType === PLAY_FOR_FUN ? "=forfun" : `=${launchData}`
    }`;
    const practice = `practice=${gameType === PLAY_FOR_REAL ? 0 : 1}`;
    const channel = `channel=${isDevice ? "mobile" : "desktop"}`;
    const origin = `origin=${encodeURIComponent(
        (isApp ? get(root, "clientConfig.AWS_MOBILE_APP_URL") : root.location.origin) ||
            "",
    )}`;

    return `${baseUrl}&${gid}&${user}&${practice}&${channel}&${origin}`;
};

export const getIsoftUrl = ({vendorGameId, launchData, gameType}: ScrProps) => {
    const {baseUrl} = getIsoftConfig();

    const mode = `mode=${gameType === PLAY_FOR_REAL ? 1 : 0}`;
    const token = `token=${
        gameType === PLAY_FOR_REAL ? encodeURIComponent(launchData) : ""
    }`;
    return `${baseUrl}/${vendorGameId}?lang=SV&currency=SEK&${mode}&${token}&autoenabled=false&allowFullScreen=false`;
};

export const getElkUrl = ({vendorGameId, launchData, gameType}: ScrProps) => {
    const {baseUrl} = getElkConfig();
    const isDevice = device.isDevice();

    const gameId = `gameid=${vendorGameId}`;
    const mode = `mode=${gameType === PLAY_FOR_REAL ? "real" : "demo"}`;
    const channel = `device=${isDevice ? "mobile" : "desktop"}`;
    const token = `token=${gameType === PLAY_FOR_REAL ? launchData : ""}`;
    return `${baseUrl}&${gameId}&${mode}&${channel}&${token}`;
};

export const getRelaxUrl = async ({vendorGameId, gameType, launchData}: ScrProps) => {
    if (gameType === PLAY_FOR_REAL) return launchData;

    try {
        const response = await pureFetch<{launchUrl: string}>(
            `https://stag-casino-client.api.relaxg.net/capi/2.0/casino/launcher/atg`,
            {
                method: "POST",
                body: JSON.stringify({
                    gameId: vendorGameId,
                    moneymode: "fun",
                }),
                headers: {
                    "Content-Type": "application/json",
                },
            },
        );

        return response.data.launchUrl;
    } catch (error: unknown) {
        console.error("Error fetching Relax data:", error);
        throw error;
    }
};

export const getSlotmillUrl = ({vendorGameId, launchData, gameType}: ScrProps) =>
    gameType === PLAY_FOR_REAL
        ? launchData
        : `https://${vendorGameId}.slotmill.com/?language=sv&org=SlotMill&currency=SEK`;

export const getPariplayUrl = ({vendorGameId, launchData, gameType}: ScrProps) => {
    const channel = device.isDevice() ? "Mobile" : "Desktop";
    const subdomain = environment !== "prod" ? "stage." : "";

    return gameType === PLAY_FOR_REAL
        ? launchData
        : `https://hubgames.${subdomain}pariplaygames.com/api/LaunchGameATG/demo?mode=fun&currency=SEK&lang=SV&operatorId=FATG&gameId=${vendorGameId}&channel=${channel}`;
};

export const getDeviceType = () => {
    // This is needed for qlikview to determine that the start
    // came from the mobile app.
    if (isApp) {
        return "atgapp";
    }

    // This is for atg.se mobile and not atgapp
    if (device.isDevice()) {
        return "mobile";
    }

    return "desktop";
};

type GameUrlProps = {
    endpoint: string;
    vendorGameId: string;
    category: GAME_CATEGORY;
    params?: string;
};

export const getLaunchUrl = ({endpoint, vendorGameId, category, params}: GameUrlProps) =>
    `/services/tokenized/${endpoint}/v1/launch?gameId=${vendorGameId}&channel=${getDeviceType()}${
        params ?? ""
    }&gameType=${category}`;

export const isPlayForFunSupported = ({vendorId, type}: Game) =>
    PLAY_FOR_FUN_VENDORS[vendorId] &&
    !(type === GAME_CATEGORY.LIVE_CASINO && vendorId === VENDOR.EVOLUTION) &&
    !(type === GAME_CATEGORY.BINGO_GAME && vendorId === VENDOR.PRAGMATICPLAY);

export const extractEvolutionTableId = (startParams: string) => {
    const mobile = device.isDevice();

    const tableIds = startParams
        .split("&")
        .filter((p) => p)
        .reduce(
            (a, pair) => ({
                ...a,
                [pair.split("=")[0]]: pair.split("=")[1],
            }),
            {} as EvolutionParams,
        );

    const tableId =
        mobile && tableIds.mobileVirtualTableId
            ? tableIds.mobileVirtualTableId
            : tableIds.tableId;

    return tableId;
};
