import {t} from "@lingui/macro";
import type {ExclusionMessage} from "@atg-responsible-gambling-shared/self-exclusion-types";
import {MuiAlertTypes} from "@atg-global-shared/alerts-types";

type Id =
    | "set-self-exclusion-success"
    | "set-self-exclusion-failure"
    | "connection-lost"
    | "wrong-password";

const getTextById = (id: Id) => {
    switch (id) {
        case "set-self-exclusion-success":
            return t`Din självavstängning har sparats.`;
        case "set-self-exclusion-failure":
            return t`Din självavstängning kunde inte genomföras, vänligen försök igen. Kontakta kundservice om problemet kvarstår.`;
        case "connection-lost":
            return t`Anslutningen till servern bröts.`;
        case "wrong-password":
            return t`Du har angett ett felaktigt lösenord, vänligen försök igen. Kontakta kundservice om problemet kvarstår.`;
        default:
            return "";
    }
};

export const SET_SELF_EXCLUSION_SUCCESS: ExclusionMessage = {
    id: "set-self-exclusion-success",
    text: getTextById("set-self-exclusion-success"),
    type: MuiAlertTypes.SUCCESS,
};

export const SET_SELF_EXCLUSION_FAILURE: ExclusionMessage = {
    id: "set-self-exclusion-failure",
    text: getTextById("set-self-exclusion-failure"),
    type: MuiAlertTypes.ERROR,
};

export const CONNECTION_LOST: ExclusionMessage = {
    id: "connection-lost",
    text: getTextById("connection-lost"),
    type: MuiAlertTypes.ERROR,
};

export const WRONG_PASSWORD: ExclusionMessage = {
    id: "wrong-password",
    text: getTextById("wrong-password"),
    type: MuiAlertTypes.ERROR,
};

export const getErrorMessage = (responseCode: number): ExclusionMessage => {
    switch (responseCode) {
        case 0:
            return CONNECTION_LOST;
        case 401:
            return WRONG_PASSWORD;
        case 400:
        default:
            return SET_SELF_EXCLUSION_FAILURE;
    }
};
